import React from "react";
import "./Manufacturer.css";
import HeadTag from "../../utils/HeadTag"


function Manufacturer() {
  return (
    <div className="container manufacturer">
      <HeadTag
      title="Nhà máy và công nghệ sản xuất của Lionraincoat.vn."
      desc= "Chúng tôi tự tin khi sở hữu những công nghệ sản xuất tiên tiến nhất để làm ra một bộ áo đi mưa chất lượng."
      keywords="áo mưa, in áo mưa, áo mưa khách hàng, áo mưa quảng cáo, áo mưa quà tặng, áo mưa cho doanh nghiệp."

      />
      <wrapper>
        <p style={{ fontWeight: "bold" }}>
          Chúng tôi dẫn đầu về công nghệ sản xuất
        </p>

        <div className="row">
          <div className="col-12 col-md-6">
            <h5 id="manufacturer-title">Lion Raincoat - Nhà Máy Sản Xuất</h5>
            <p className="pt-3">
              Được trang bị đầy đủ các công nghệ máy móc cũng như nhân công,
              chúng tôi nắm giữ mọi quy trình để sản xuất một bộ áo đi mưa đạt
              tiêu chuẩn , an toàn, chất lượng và thời trang. Thời trang sản
              xuất nhanh cũng là một điểm mà Lionraincoat.com.vn tự hào.
            </p>
          </div>
          <div className="col-12 col-md-6">
            <iframe
              title="quytrinhsxaomua"
              width={560}
              height={315}
              src="https://firebasestorage.googleapis.com/v0/b/lionraincoat-7dc19.appspot.com/o/lionraincoat-video-manufacturing%2FManufacturing_Lion.mp4?alt=media&token=def1d5eb-23a3-4c9d-a9c0-2e28551ddb40"
              frameBorder={0}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </wrapper>
    </div>
  );
}

export default Manufacturer;
