import React, { useState } from 'react'
import axios from 'axios'
import './AskingPriceBox.css'

export default function AskingPriceBox() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const handleSubmit = (evt) => {
        evt.preventDefault();
        const contact = {
            name: name,
            phone: phone
        }

        axios.post('/api/contacts/submit', contact)
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
            alert("Chúng mừng bạn đã liên hệ thành công!")
            window.location.reload()
    }
    return (
        <div classname="asking-price-box">
            <div className="modal fade" id="asking-price-modal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="asking-price-title">Bạn có thể liên hệ theo 2 cách:</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="container cach-1">
                                    <b>Cách 1: </b><div>Gọi cho chúng tôi để được tư vấn ngay: <b style={{color:"#B12323"}}>0339451133</b></div>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="container cach-2">
                                    <b>Cách 2: </b><div>Điền thông tin và số điện thoại, nhân viên chúng tôi sẽ gọi và tư vấn cho bạn.</div>
                                    <div className="form-asking-price pt-3">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                           
                                                <input type="text" className="form-control" id="client-name" placeholder="Tên *" required value={name} onChange={e => setName(e.target.value)}  />
                                            </div>
                                            <div className="form-group">
                                                <input type="phone" className="form-control" id="client-phone" placeholder="Số Điện Thoại *" required  value={phone} onChange={e => setPhone(e.target.value)} />
                                            </div>
                            
                                            <button type="submit" className="btn btn-primary" id="askingprice-btn">Gọi Lại Cho Tôi!</button>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    )
}
