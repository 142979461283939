import React from "react";

import hvnclc2020 from "../../assets/achivements/logo-hvnclc2020.png";
import hvnclc2019 from "../../assets/achivements/logo-hvnclc2019.png";
import hvnclc2018 from "../../assets/achivements/logo-hvnclc2018.png";
import hvnclc2017 from "../../assets/achivements/logo-hvnclc2017.png";
import achievement2 from "../../assets/achivements/achivement1.jpg";

const sub_achievements = [
  {
    photo: hvnclc2019,
    title: "Hàng Việt Nam Chất Lượng Cao 2019",
    content:
      "Lionraincoat.com.vn đạt giải thưởng HVNCLC do nguời tiêu dùng bình chọn năm 2019.",
  },
  {
    photo: hvnclc2018,
    title: "Hàng Việt Nam Chất Lượng Cao 2018",
    content:
      "Lionraincoat.com.vn đạt giải thưởng HVNCLC do nguời tiêu dùng bình chọn năm 2018.",
  },
  {
    photo: hvnclc2017,
    title: "Hàng Việt Nam Chất Lượng Cao 2017",
    content:
      "Lionraincoat.com.vn đạt giải thưởng HVNCLC do nguời tiêu dùng bình chọn năm 2018.",
  },
  {
    photo: achievement2,
    title: "Thương Hiệu Dẫn Đầu",
    content:
      "Lionraincoat.com.vn đạt giải thưởng THDĐ năm 2017.",
  },
];

const Achievements = () => {
  const AchievementItem = ({ photo, title, content, index }) => {
    return (
      <div className="card achievement-item raised-1">
        <div className="card-wrapper">
          <div className="overlay">
            <div className="overlay-text-section">
              <h5 style={{ color: "white" }}>{title}</h5>
              <p>{content}</p>
            </div>
          </div>
          <div className="card-img">
            <img
              src={photo}
              className="w-100 card-img-top"
              alt={`Achievement ${index + 1}`}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      id="achievements"
      className="site-padding"
      style={{ backgroundColor: "#efefef" }}
    >
      <div className="container">
        <div className="title-section">
          <div className="row justify-content-center text-center">
            <div className="col-md-12 mb-3">
              <h2
                className="title mb-3 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay
              >
                thành tựu
              </h2>
            </div>
          </div>
        </div>
        <div className="achivement-section">
          <div className="row d-flex justify-content-center">
            <div
              className="col-12 col-lg-6 mb-3"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card raised-1 main-achievement">
                <div className="card-wrapper">
                  <div className="card-img">
                    <img
                      src={hvnclc2020}
                      className="w-100"
                      alt="Achievement 1"
                    />
                  </div>
                  <div className="card-body">
                    <h3 className="card-title">Hàng Việt Nam Chất Lượng Cao 2020</h3>
                    <p className="card-text">
                    Lionraincoat.com.vn đạt giải thưởng HVNCLC do nguời tiêu dùng bình chọn năm 2020.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-6 mb-3">
              <div className="row pb-3 h-50">
                {sub_achievements.length !== 0 &&
                  sub_achievements.map((achievement, index) => {
                    if (index < 2)
                      return (
                        <div
                          className="col-6"
                          key={index}
                          data-aos="fade-up"
                          data-aos-delay={index * 100 + 100}
                        >
                          <AchievementItem
                            title={achievement.title}
                            photo={achievement.photo}
                            content={achievement.content}
                            index={index}
                          />
                        </div>
                      );
                  })}
              </div>
              <div className="row pt-3 h-50">
                {sub_achievements.length !== 0 &&
                  sub_achievements.map((achievement, index) => {
                    if (index >= 2)
                      return (
                        <div
                          className="col-6"
                          key={index}
                          data-aos="fade-up"
                          data-aos-delay={index * 100 + 100}
                        >
                          <AchievementItem
                            title={achievement.title}
                            photo={achievement.photo}
                            content={achievement.content}
                            index={index}
                          />
                        </div>
                      );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
