import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ProductListPage.css";
import Products from "./Products";
import HeadTag from "../../utils/HeadTag";
import { production_URL } from "../../Constant/url";

const ProductListPage = (props) => {
  const { id } = props.match.params;
  const [cat, setCat] = useState({});
  const [product, setProduct] = useState([]);

  const fetchData = async () => {
    const productsResult = await axios.get(`/api/products/cat/${id}`);

    setCat(productsResult.data.category);
    setProduct(productsResult.data.products);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const ogImg = product.length !== 0 && product[0].photos[0].source

  return (
    <div className="productListPage">
      <HeadTag
        title={`${
          cat.name ? cat.name : "Áo mưa thời trang"
        } - Lionraincoat.com.vn`}
        desc="Lionraincoat.com.vn là thương hiệu sản xuất thời trang áo quần đi mưa hàng đầu Việt Nam. Cung cấp các giải pháp đi mưa như áo mưa phản quang, áo mưa măng tô, áo mưa cánh dơi, áo mưa đi phượt, áo mưa bộ chính hãng để làm quà tặng, in logo, thương hiệu và slogan cho các doanh nghiệp."
        keywords={`${cat.name}, áo mưa, áo mưa bộ, áo mưa cho bé, áo mưa măng tô, áo mưa trẻ em, áo mưa 1 người, áo mưa 2 người, áo mưa cao cấp, áo mưa xe máy, áo mưa phản quang, áo đi mưa, in áo mưa, áo mưa doanh nghiệp, may áo mưa, áo mưa quảng cáo, áo mưa quà tặng`}
        ogURL = {production_URL + `#/products/${id}`}
        ogImg={ogImg}
      />
      <div
        className="product-banner mb-5"
        style={{
          background: `url(${cat.thumpnail}) center center no-repeat `,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text-in-product-banner">
                <h1 id="p1">{cat.name}</h1>
                <p id="p2">{cat.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-5">
        <hr className="hr-new" />

        <Products products={product} />
      </div>
    </div>
  );
};

export default ProductListPage;
