import React, { useState, useEffect } from 'react'
import './sale.css'
import axios from 'axios'
import moment from "moment";

const SaleDetails = (props) => {
    const { id } = props.match.params;
    const [sale, setSale] = useState({})

    const fetchData = async () => {
        const result = await axios.get(`/api/sales/${id}`)

        setSale(result.data)
    }
    useEffect(() => {
        fetchData();
    }, [id])
    return (
        <div className="sale-details container-fluid">
            <wrapper>
                <div className="row-d-flex justify-content-center">
                    <div className="col-lg-12 text-center">
                        <h5>Khuyến Mãi</h5>
                        <h1>{sale.title}</h1>

                        <div className="sale-image-content">
                            <img
                                src={sale.thumbnail}
                                className="img-fluid" />
                        </div>
                        <hr id="hr-sale" />
                        <div className="text-left">
    <p style={{fontWeight:"bold"}}>NGÀY BẮT ĐẦU: {" "}
    <span className="text-warning-sale-detail" style={{ fontWeight: "bold" }}>
                {moment(sale.startedAt).format("DD/MM/YYYY")}
              </span>
    </p>
    <p style={{fontWeight:"bold"}}>NGÀY KẾT THÚC: {" "}
    <span className="text-warning-sale-detail" style={{ fontWeight: "bold" }}>
                {moment(sale.expiredAt).format("DD/MM/YYYY")}
              </span>
    </p>
                            <p style={{fontWeight:"bold"}}>THÔNG TIN CHI TIẾT:</p>
                        </div>
                        <p className="text-left sale-content">
                            {sale.content}
                        </p>


                    </div>
                </div>

            </wrapper>
        </div>
    )
}

export default SaleDetails;
