import React from "react";
import story from "../../assets/story-gif-2.gif";
import "./Story.css";
import processImage from "../../assets/Process-Diagram.png";
import HeadTag from "../../utils/HeadTag"

function Story() {
  return (
    <div className="container story">
      <HeadTag
      title="Câu chuyện của Lionraincoat.com.vn."
      desc= "Chúng tôi luôn mang trong mình những câu chuyện và sứ mệnh to lớn khi tham gia vào lĩnh vực thời trang đi mưa."
      keywords="áo mưa, in áo mưa, áo mưa khách hàng, áo mưa quảng cáo, áo mưa quà tặng, áo mưa cho doanh nghiệp, câu chuyện áo mưa."

      />
      <wrapper>
        <p style={{ fontWeight: "bold" }}>
          Chúng tôi khác với những công ty khác ở điểm đội ngũ sản xuất, thiết
          kế và bán hàng của chúng tôi tập hợp những chuyên gia có kinh nghiệm
          trong lĩnh vực sản xuất áo đi mưa. Với hơn 20 năm kinh nghiệm , chúng
          tôi đã trở thành người bạn đồng hành của nhiều doanh nghiệp trong và
          ngoài nước. Lion RAINCOAT sẽ giúp các khách hàng với những sản phẩm
          tốt nhất được sản xuất tại Việt Nam.
        </p>
        <div className="content-story">
          <div className="row">
            <img
              src={story}
              width="20%"
              height="20%"
              alt="raincoat-gif"
              className="img-fluid"
            />
          </div>

          <div className="row">
            <div className="col-12 col-lg-6 mb-3">
              <h5 id="story-title">Lion Raincoat - Câu Chuyện Áo Mưa</h5>
              <h7 id="story-subtitle-h7">
                Lionraincoat.com.vn - <b>Một nỗ lực không ngừng vươn lên.</b>
              </h7>
              <p className="pt-3">
                Từ tiền thân là cơ sở sản xuất áo đi mưa thành lập vào ngày
                7/12/1997, với 100% vốn tư nhân, Công ty TNHH Sản xuất - Thương
                mại Thanh Bình Nguyên đã được Sở Kế hoạch & Đầu tư Tp. Hồ Chí
                Minh cấp giấy chứng nhận đăng ký kinh doanh số 0310562739 năm
                2011.
              </p>
              <p>
                Luôn lấy người tiêu dùng làm trọng tâm để xây dựng chiến lược và
                định nghĩa thương hiệu, Thanh Bình Nguyên với nhãn hiệu Lion đã
                và đang từng bước tiên phong trong lĩnh vực sản xuất áo đi mưa.
                Với phương châm "Chất lượng, uy tín, giá cả hợp lý", suốt 18 năm
                qua, công ty đã không ngừng cải tiến các mẫu mã cùng ứng dụng
                nguồn nguyên liệu cao cấp để tạo ra sản phẩm có chất lượng cao,
                an toàn và phù hợp với xu hướng tiêu dùng hiện đại.
              </p>
              <p>
                Chuyên sản xuất sản phẩm áo đi mưa vừa phục vụ nhu cầu tất yếu
                của người tiêu dùng, vừa góp phần không nhỏ trong việc định
                hướng xu hướng thời trang, đội ngũ thiết kế Thanh Bình Nguyên đã
                luôn tìm tòi, học hỏi để không chỉ phát triển về số lượng mẫu
                mã, mà còn giúp đưa các chi tiết thời trang tinh tế nhất vào
                từng sản phẩm. Với sự phát triển vững mạnh cùng nhiều đối tác
                lớn cả trong và ngoài nước, chúng tôi tự tin sẽ giữ vững vị thế
                và gặt hái nhiều thành công hơn nữa. Trên hết thảy, chúng tôi
                luôn quan niệm, thành quả lớn nhất mà công ty đạt được không gì
                khác ngoài sự hài lòng của khách hàng mỗi khi khoác lên mình bộ
                sản phẩm mà từng người trong công ty chúng tôi đã cùng nhau nỗ
                lực tạo nên.
              </p>
            </div>

            <div className="col-12 col-lg-6 mb-3">
              <div className="box-1">
                {" "}
                <h5 id="story-title">
                  Một số yêu cầu của đặc thù của khách hàng
                </h5>
                <div className="list-clients-about">
                  <ul className="list-clients">
                    <li>
                      Mỗi năm chúng tôi đều xuất khẩu từ 1 đến 2 container Áo Mưa Bộ 1 Lớp A3 đến Bắc Triều Tiên. 
                      Mẫu áo phải cam kết đạt được những yêu cầu như: chất liệu vải đẹp, thơm, mịn, chống thắm nước và bền bĩ.
                    </li>
                    <li>
                      Các doanh nghiệp hằng năm đều liên hệ chúng tôi để in ấn thương hiệu, logo, slogan trước ngực và sau lưng để làm quà tặng cho khách hàng.
                    </li>
                    <li>
                      Khách hàng tìm đến chúng tôi như một công ty cung cấp "Thời Trang Đi Mưa" chứ không phải đơn thuần là áo quần đi mưa.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="box-1">
                {" "}
                <h5 id="story-title">Định Hướng Thâm Nhập Thị Trường</h5>
                <div className="list-clients-about">
                  <p>
                    Các công ty, tập đoàn lớn luôn có nhu cầu làm áo đi mưa để
                    chạy event hay quà tặng cao cấp và chất lượng với số lượng
                    nhỏ (chỉ từ vài áo đến vài chục áo), nhưng hầu như các nhà
                    cung cấp hiện tại không muốn làm hoặc không đáp ứng được
                    chất lượng đơn hàng do không thể chủ động trong các công
                    đoạn sản xuất. (Vì họ phải đi đặt lại ở từng đơn vị gia công
                    khác, nên luôn gặp khó khăn về màu vải, chất liệu vải, và
                    chất lượng may in thêu).
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-6 mb-3">
              <h5 id="story-title">Các Cột Móc Phát Triển Quan Trọng</h5>
              <div className="container text-center">
                <img
                  className="img-fluid"
                  height="80%"
                  width="80%"
                  src={processImage}
                  alt="process"
                />
              </div>
            </div>

            <div className="col-12 col-lg-6 mb-3">
              <h5 id="story-title">Sứ Mệnh Tương Lai</h5>
              <h7 id="story-subtitle-h7">
                Lionraincoat.com.vn -{" "}
                <b>Khi Áo Mưa Trở Thành Thời Trang Đi Mưa.</b>
              </h7>
              <p className="pt-3">
                Cụm từ "Thời Trang Đi Mưa" là sứ mệnh mà chúng tôi đang nhắm tới
                trong tương lai. Với kế hoạch tạo ra các xu hướng thời trang
                phục vụ cho việc đi mưa, chúng tôi sẽ không ngừng nỗ lực và cho
                ra đời các sản phẩm để đạt được sứ mệnh đó.
              </p>
            </div>
          </div>
        </div>
      </wrapper>
    </div>
  );
}

export default Story;
