import React from "react";

const ProductDesc = ({desc}) => {
  return (
    <div className="product-description">
      <p>
        {desc}
      </p>
    </div>
  );
};

export default ProductDesc;
