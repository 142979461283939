import React from 'react'
import logo from "../../assets/logo-brand-web1.png"
import "./InfoBanner.css"
import { Link } from 'react-router-dom'

export default function InfoBanner() {
    return (
        <div className="info-banner">
            <div className="container">
                <div className="row align-items-center d-flex" id="info-banner-inside">
                    <div className="col-lg-4 col-md-3 col-12 mb-5 mb-md-0 ">
                        <img src={logo} width={250} height={83} className="d-inline-block align-top img-fluid " alt="lionraincoat" />
                    </div>
                    <div className="col-lg-8 col-md-9 col-10 mb-3 mb-md-0">
                        <div className="info-banner-text">
                            <strong style={{paddingBottom: '8px'}}>Thời Trang Đi Mưa Cao Cấp</strong>
                            <p>Những chiếc áo mưa phong cách tạo nên cảm giác thời thượng và an toàn cho cuộc sống hằng ngày. Chính vì thế, chúng tôi luôn nỗ lực để tạo ra những sản phẩm tuyệt vời nhất.</p>
                            <div className="pt-1">
                            <Link className="linkInfoBanner" to={"/story"} >Tìm hiểu thêm</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
