import React from "react";
import "./CompanyCulture.css";
import culture from "../../assets/company-culture.jpg";
import culture2 from "../../assets/company-culture-2.jpg";
import HeadTag from "../../utils/HeadTag"

function CompanyCulture() {
  return (
    <div className="container culture">
      <HeadTag
      title="Văn hoá công ty Lionraincoat.com.vn."
      desc= "Chúng tôi tin rằng văn hoá công ty chính là xương sống của công ty đó. Tại Lionraincoat.com.vn, mọi hành động tốt đều được ghi nhận và tuyên dương."
      keywords="áo mưa, in áo mưa, áo mưa khách hàng, áo mưa quảng cáo, áo mưa quà tặng, áo mưa cho doanh nghiệp."

      />
      <wrapper>
        <p style={{ fontWeight: "bold" }}>
          “Hạnh phúc không chỉ là đích đến, mà còn là trên từng chặng đường đi.”
          Che Guevara
        </p>
      </wrapper>
      <div className="content-culture">
        <div className="row">
          <img
            src={culture}
            width="40%"
            height="40%"
            alt="raincoat-gif"
            className="img-fluid"
          />
        </div>

        <div className="row">
          <div className="col-12 col-lg-6 order-1 order-lg-0 ">
            <h5 id="culture-title">Lion Raincoat - Văn Hóa Công Ty</h5>
            <h7 id="culture-subtitle">
              Tạo lập một doanh nghiệp để kiếm tiền hay làm một điều gì đó thật
              sự đặc biệt?
            </h7>
            <p className="pt-3">
              Kiếm tiền là điều quan trọng. Nhưng, đối với Lionraincoat, chúng
              tôi còn có một ước mơ lớn lao hơn thế. Ước mơ đó chính là đem lại
              giá trị cho xã hội thông qua việc cung cấp các bộ áo đi mưa an
              toàn và chất lượng. Mỗi khách hàng xứng đáng được nhận những giá
              trị về vật chất và tinh thần.{" "}
            </p>
            <p>
              Tại Lionraincoat.com.vn, chúng tôi còn tham lam hơn thế. Chúng tôi
              luôn cho rằng mình đang nắm giữ một sứ mệnh mang lại một thương
              hiệu, một xu hướng về thời trang đi mưa cho Việt Nam.
            </p>
            <p>
              Vì thế, việc mua áo đi mưa sẽ không còn theo một quy chuẩn cũ. Áo
              đi mưa chính là thời trang đi mưa
            </p>
            <p>
              Nếu những điều trên làm bạn hứng thú vì nó cùng với hệ giá trị của
              bạn, hãy tham gia cùng chúng tôi để trở thành một thành viên tiếp
              theo của ngôi nhà chung Lionraincoat.com.vn nhé!
            </p>
            <p>Trân trọng,</p>
            <p id="signature-culture">Gia đình Lionraincoat.com.vn</p>
          </div>
          <div className="col-12 col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0">
            <div className="container">
              <img
                src={culture2}
                alt="culture"
                height="80%"
                width="80%"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyCulture;
