import React from 'react'


const ProductColors = ({colors, onChangeColor}) => {
    return (
        <div className="product-colors">
            <div className="colors-grid">
            {colors.length!==0 && colors.map((color, index)=>(
                <a index={index} className="color-swap" style={{backgroundColor: color.colorCode}} onClick={()=>onChangeColor(color.colorCode)} title={color.color}></a>
            ))}
            </div>
            
        </div>
    )
}

export default ProductColors
