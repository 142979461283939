import React from 'react'
import image1 from '../../assets/contactImage1.jpg'
import image2 from '../../assets/contactImage2.jpg'
import image3 from '../../assets/contactImage3.png'
import contacter1 from '../../assets/contacter.jpg'
import contacter2 from '../../assets/ba.jpg'
import contacter3 from '../../assets/me.jpg'
import './ContactCard.css'

export default function ContactCard() {
    const contactList = [
        {
            image: image1,
            contactTitle: "Nhà Máy Sản Xuất",
            contactInfo1: "Làm áo mưa doanh nghiệp.",
            contactInfo2: "Áo mưa quảng cáo, sự kiện.",
            contactInfo3: "Áo mưa quà tặng nhân viên, khách hàng.",
            contacterImage1: contacter2,
            contacterImage2: contacter3,
            contacterName1: "Mr.Thanh",
            contacterName2: "Mrs.Tram",
            phone1: "Mr.Thanh 0913804925 - Line 1",
            phone2: "Mrs.Tram 0339451133 - Line 2",
            workingHour: "8h30 - 17h, từ T2-T7 (CN nghỉ)",
            email: "info@lionraincoat.com.vn",
            address: "H30 - H31, Cư Xá Phú Lâm B, Phường 13, Quận 6, TP.HCM"
        },
        {
            image: image2,
            contactTitle: "Văn Phòng Đại Diện",
            contactInfo1: "Đặt áo mưa số lượng lớn.",
            contactInfo2: "Trở thành đại lý chiến lược.",
            contactInfo3: "Xuất khẩu áo mưa",
            contacterImage1: contacter2,
            contacterImage2: contacter3,
            contacterName1: "Mr.Thanh",
            contacterName2: "Mrs.Tram",
            phone1: "Mr.Thanh 0913804925 - Line 1",
            phone2: "Mrs.Tram 0339451133 - Line 2",
            workingHour: "8h30 - 17h, từ T2-T7 (CN nghỉ)",
            email: "info@lionraincoat.com.vn",
            address: "H30 - H31, Cư Xá Phú Lâm B, Phường 13, Quận 6, TP.HCM"
        },
        {
            image: image3,
            contactTitle: "Aomualion.vn",
            contactInfo1: "Mua lẻ sản phẩm áo mưa.",
            contactInfo2: "In áo mưa số lượng ít.",
            contactInfo3: "Tư vấn size áo - Đổi trả",
            contacterImage1: contacter1,
            contacterImage2: contacter3,
            contacterName1: "Mr.Minh",
            contacterName2: "Mrs.Tram",
            phone1: "Mr.Minh 0918695449 - Line 1",
            phone2: "Mrs.Tram 0339451133 - Line 2",
            workingHour: "8h30 - 17h, từ T2-T7 (CN nghỉ)",
            email: "info@lionraincoat.com.vn",
            address: "217/14 Bà Hom, Phường 13, Quận 6, TP.HCM"
        }



    ]

    const contactGrid = contactList.map((contact, index) => (
        <div key={index} className="card-section mx-auto">

            <div className="col-4">
                <div className="card" style={{ width: '18rem',height:'930px' }}>
                    <img className="card-img-top" width="286px" height="183px" src={contact.image} alt="" />
                    <div className="card-body">
                        <div className="contact-card-box-1">
                        <h5 className="card-title" id="contact-card-title">{contact.contactTitle}</h5>
                        <p className="card-text-title text-left">Quý Khách Có Nhu Cầu</p>
                        <p className="card-text text-left">{contact.contactInfo1}</p>
                        <p className="card-text text-left">{contact.contactInfo2}</p>
                        <p className="card-text text-left">{contact.contactInfo3}</p>
                        <p className="card-text-title text-left">Xin quý khách vui lòng liên lạc theo thông tin bên dưới để được phục vụ tốt nhất.</p>
                        </div>
                        <hr />
                        <div className="contact-card-box-2">
                        <h7 id="contacter text-center">Người nhận thông tin</h7>
                        <div className="row mt-2">
                            <div className="col-6">
                                <img src={contact.contacterImage1} className="rounded-circle img-fluid" alt="Cinque Terre" width={108} height={108} />
                            </div>

                            <div className="col-6">
                                <img src={contact.contacterImage2} className="rounded-circle img-fluid" alt="Cinque Terre" width={108} height={108} />
                            </div>

                        </div>

                        <div className="row mt-2">
                            <div className="col-6">
                                <p>{contact.contacterName1}</p>
                            </div>

                            <div className="col-6">
                                <p>{contact.contacterName2}</p>

                            </div>

                        </div>
                        </div>
                        <hr />
                        <div className="contact-card-box-3">
                            <p id="phone1"><i class="fa fa-phone mr-2" aria-hidden="true"></i>
                                {contact.phone1}</p>
                            <p id="phone2"><i class="fa fa-phone mr-2" aria-hidden="true"></i>
                                {contact.phone2}</p>
                            <p id="workingHour"> <i class="fa fa-clock-o mr-2" aria-hidden="true"></i>
                                {contact.workingHour}</p>
                            <p id="email"> <i class="fa fa-envelope mr-2" aria-hidden="true"></i>
                                {contact.email}</p>
                            <p id="address"> <i class="fa fa-map-marker mr-2" aria-hidden="true"></i>
                                {contact.address}</p>

                        </div>


                    </div>
                </div>

            </div>

        </div>
    )
    )
    return (
        <div className="row text-center" id="contact-card">
            {contactGrid}
        </div>
    )
}
