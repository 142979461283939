import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";

import photo1 from "../../assets/sanxuat.png";
import photo2 from "../../assets/inan.png";
import photo3 from "../../assets/baoquan.jpg";

const Techniques = () => {
  return (
    <div
      id="techniques"
      className="site-padding gradient-2"
      // style={{ backgroundColor: '#efefef' }}
    >
      <div className="container">
        <div className="title-section">
          <div className="row justify-content-center text-center">
            <div className="col-md-7 mb-3">
              <h2
                style={{color: "#ffffff"}}
                className="title mb-3 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay
              >
                Công nghệ
              </h2>
              <p
                className="lead text-white aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Ở Lionraincoat.com.vn, chúng tôi luôn học hỏi, chuyển giao và áp
                dụng nhiều công nghệ tiên tiến khác nhau để phục vụ cho việc sản
                xuất những bộ áo đi mưa hơn 20 năm nay.
              </p>
              <Link to={"/"}>
                <button
                  className="btn btn-danger text-uppercase"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Xem thêm
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="techniques-container-row row justify-content-center">
          <div
            className="card p-3 col-12 col-md-6 col-lg-4"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="card-wrapper ">
              <div className="card-img">
                <div className="overlay"></div>
                <div className="overlay-text-section section-btn">
                  <Button type="danger" size="large" shape="round">
                    Learn more
                  </Button>
                </div>
                <img src={photo1} alt="tech1" className="w-100" />
              </div>
              <div className="card-body">
                <h4 className="card-title">Sản Xuất</h4>
                <p>
                  Chúng tôi có những dây chuyền sản xuất khác nhau theo thứ tự
                  như: Cắt (Bán Thành Phẩm) May Thành Phẩm, Lăn - Ép Đường May,
                  Kiểm Phẩm, Dập Nút, Xếp - Đóng Gói và Vận Chuyển.{" "}
                </p>
              </div>
            </div>
          </div>
          <div
            className="card p-3 col-12 col-md-6 col-lg-4"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="card-wrapper ">
              <div className="card-img">
                <div className="overlay"></div>
                <div className="overlay-text-section section-btn">
                  <Button type="danger" size="large" shape="round">
                    Learn more
                  </Button>
                </div>
                <img src={photo2} alt="tech1" className="w-100" />
              </div>
              <div className="card-body">
                <h4 className="card-title">In Ấn</h4>
                <p>
                  Áp dụng nhiều phương pháp in phổ biến đến cao cấp để phục vụ
                  các doanh nghiệp có nhu cầu in thương hiệu lên các bộ áo đi
                  mưa Lion.
                </p>
              </div>
            </div>
          </div>
          <div
            className="card p-3 col-12 col-md-6 col-lg-4"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="card-wrapper ">
              <div className="card-img">
                <div className="overlay"></div>
                <div className="overlay-text-section section-btn">
                  <Button type="danger" size="large" shape="round">
                    Learn more
                  </Button>
                </div>
                <img src={photo3} alt="tech1" className="w-100" />
              </div>
              <div className="card-body">
                <h4 className="card-title">Bảo Quản</h4>
                <p>
                  Chúng tôi áp dụng những vật liệu trong dây chuyển sản xuất để
                  tăng tính bền bĩ của những bộ áo đi mưa Lion. Việc bảo quản
                  thành phẩm sẽ được ưu tiên hàng đầu nhằm đưa đến những khách
                  hàng những sản phẩm tốt nhất.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Techniques;
