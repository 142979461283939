import React from "react";
import OwlCarousel from "react-owl-carousel";
// import { Link } from 'react-router-dom'

import { testimonial_data } from "../../data/static-data";

const CustomersFeedback = () => {
  const TestimonialItem = ({ name, content, icon, linkTo, company }) => {
    return (
      <div className="card bg-white">
        <div className="card-header border-0">
          <div className="header-wrap">
            <img
              src={icon}
              alt="Person Icon"
              className="align-self-center rounded-circle side-align"
            />
            <div className="header-body ml-3 my-auto">
              <p className="font-weight-bold mb-0">{name}</p>
              <small className="text-muted font-italic">{company}</small>
            </div>
          </div>
        </div>
        <div className="card-body" style={{height:"150px"}}>
          <p className="card-text">{content}</p>
        </div>
      </div>
    );
  };

  return (
    <div id="testimonials" className="site-padding">
      <div className="container">
        <div className="title-section">
          <h1
            className="title"
            data-aos="fade-up"
            data-aos-duration="900"
            // style={{ color: "#ffffff" }}
          >
            Phản hồi khách hàng
          </h1>
        </div>
        <div
          className="testimonials-slide"
          data-aos="flip-up"
          data-aos-duration="450"
        >
          <OwlCarousel
            slideBy={3}
            loop
            responsiveClass={true}
            responsive={{
              0: {
                items: 1,
              },
              576: {
                items: 2,
              },
              768: {
                items: 3,
              },
            }}
            items={3}
            margin={5}
            autoplay
            // autoplaySpeed={400}
            smartSpeed={350}
            dots={false}
            className="owl-theme"
          >
            {testimonial_data.length !== 0 &&
              testimonial_data.map((item, index) => (
                <div
                  className="item"
                  style={{ margin: "20px 10px", width: "100%" }}
                  key={index}
                >
                  <TestimonialItem
                    name={item.name}
                    content={item.content}
                    icon={item.icon}
                    linkTo={item.linkTo}
                    company={item.company}
                  />
                </div>
              ))}
          </OwlCarousel>
        </div>
      </div>
    </div>
  );
};

export default CustomersFeedback;
