import React from 'react'
import { Parallax, Background } from 'react-parallax'

const SectionBanner = ({ title, content, bgImage, bgStyle, overlayStyle, titleStyle }) => {
    return (
        <div className="service-header-section">
            <Parallax
                strength={300}
                contentClassName="site-padding"
            >
                <Background className="custom-bg">
                    <img src={bgImage} alt="cp-background" style={bgStyle} />
                </Background>
                <div className="cp-overlay" style={overlayStyle}></div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 align-self-center text-center">
                            <h1 className="cp-title cp-font-style" style={titleStyle}>{title}</h1>
                            <p className="cp-text cp-font-style pb-3" >{content}</p>
                        </div>
                    </div>
                </div>

            </Parallax>
        </div>
    )
}

export default SectionBanner
