import React from 'react'
import SectionBanner from '../section-banner/SectionBanner'
import photo1 from '../../../assets/handshake.png'
import photo3 from '../../../assets/themanhvasumenh.png'
import photo2 from '../../../assets/images/BITMAU11.png'
import bannerPhoto from '../../../assets/backgrounds/banner-all-brands.png'
import HeadTag from '../../../utils/HeadTag'
import { production_URL } from '../../../Constant/url'
import logo from '../../../assets/logo_LION.png'

const Company = () => {
    return (
        <div id='company'>
            <HeadTag
                title="Áo mưa doanh nghiệp | Đồng phục doanh nghiệp - Lionraincoat.com.vn"
                desc="Bạn muốn công ty, doanh nghiệp của mình luôn nổi bật, chuyên nghiệp và có những quà tặng quý giá cho khách hàng. Hãy đồng hành cùng nhau ngày hôm nay!"
                keywords="áo mưa, in áo mưa, áo mưa khách hàng, áo mưa quảng cáo, áo mưa quà tặng, áo mưa cho doanh nghiệp."
                ogURL={production_URL + '/#/company'}
                 ogImg={logo}
            />
            <SectionBanner
                overlayStyle={{ opacity: '0.4', backgroundColor: 'rgb(35,35,35)' }}
                titleStyle={{ color: "#B12323" }}
                bgStyle={{ transform: 'translateY(120px)' }}
                bgImage={bannerPhoto}
                title='DOANH NGHIỆP'
                content='Con đường nào ta đi có người bạn đồng hành tốt, đó là con đường ngắn nhất.'
            />

            <div className="content-1 bg-light content-padding">
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-6 mb-3 mb-lg-0">
                            <div className="img-wrapper bg-left">
                                <img src={photo1} alt="Lion Company" className="w-100" />
                            </div>
                        </div>
                        <div className="col-lg-6 m-auto align-items-center" style={{ padding: '0 2rem' }}>
                            <h1 className="content-title">Doanh nghiệp là người bạn đồng hành</h1>
                            <p className="content-text">
                                Trong suốt 20 năm qua, chúng tôi vận hành công ty với tiêu chí <strong><em>"Doanh nghiệp là người bạn đồng hành"</em></strong>
                                . Chính vì thế, chúng tôi đã cố gắng nâng cao niềm tin bằng cách đem lại những sản phẩm và dịch vụ tốt nhất cho các doanh nghiệp trong và ngoài nước.
                                . <strong><em>"Muốn đi nhanh thì đi một mình, muốn đi xa thì đi cùng nhau"</em></strong>, tập thể Lionraincoat.com.vn luôn khẳng định uy tín của mình từ khi được thành lập từ 1997 cho đến nay, chúng tôi luôn tự hào
                                vì đã có được đội ngũ công nhân viên kinh nghiệm, đáng tin cậy và sáng tạo để chúng tôi có thể làm vừa lòng những khách hàng khó tính nhất.
                            </p>



                        </div>
                    </div>
                </div>
            </div>

            <div className="content-1 bg-light content-padding">
                <div className="container">
                    <div className="row" >
                        <div className="col-lg-6 mb-3 mb-lg-0 order-lg-2">
                            <div className="img-wrapper bg-right">
                                <img src={photo3} alt="Lion Company" className="w-100" />
                            </div>
                        </div>
                        <div className="col-lg-6 m-auto align-items-center" style={{ padding: '0 2rem' }}>
                            <h1 className="content-title">Thế mạnh và sứ mệnh</h1>
                            <p className="content-text">Trong quá trình hoạt động của doanh nghiệp, chúng tôi luôn lắng nghe, học hỏi những ý kiến từ những doanh nghiệp mà chúng tôi đã hợp tác. Chính vì thế, hiện nay chúng tôi đã có hơn 80+ loại sản phẩm đi mưa được nghiêng cứu và thiết kế dựa trên những ý kiến đó.
                            Chúng tôi còn tự tin về kinh nghiệm trong quy trình sản xuất, chăm sóc khách hàng được tích lũy trong suốt hơn 20 năm nay.
                            </p>
                            <p className="content-text">
                                Một sứ mệnh to lớn hơn mà chúng tôi muốn mang lại cho nước nhà, sứ mệnh đó được gói gọn trong một cụm từ <strong><em>"Thời Trang Đi Mưa""</em></strong>. Chúng tôi muốn khẳng định cho mọi người biết rằng
                                việc đi mưa sẽ không đơn thuần chỉ là đi mưa, chúng tôi muốn tạo một xu thế mới cho ngành thời trang đi mưa.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-0 content-padding">
                <div className="halfbg"></div>
                <div className="container" >
                    <div className="wrapper" style={{ zIndex: '100' }}>
                        <p className="content-text"> Luôn lấy người tiêu dùng làm trọng tâm để xây dựng chiến lược và định nghĩa thương hiệu,
                        Thanh Bình Nguyên với nhãn hiệu Lion đã và đang từng bước tiên phong trong lĩnh vực sản xuất áo đi mưa.
                            Với phương châm <strong><em>"Chất lượng, uy tín, giá cả hợp lý"</em></strong>, suốt 18 năm qua, công ty đã không ngừng cải tiến các mẫu mã cùng ứng dụng nguồn nguyên liệu cao cấp để tạo ra sản phẩm có chất lượng cao, an toàn và phù hợp với xu hướng tiêu dùng hiện đại.</p>
                    </div>
                </div>
            </div>

            <div className="content-2 content-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 ">
                            <div className="content-wrapper px-4 ">
                                <h2 className="content-title">Tại sao chọn chúng tôi?</h2>
                                <div className="sub-content">
                                    <div className="d-flex">
                                        <div className="icon-wrapper">
                                            <span class="mbri-magic-stick amp-iconfont"></span>
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Đa dạng mẫu mã</h2>
                                            <p className="content-text">Chúng tôi sở hữu 80+ loại áo đi mưa khác nhau, sẵn sàng để phục vụ mọi nhu cầu đa dạng của khách hàng.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sub-content">
                                    <div className="d-flex">
                                        <div className="icon-wrapper">
                                            <span class="mbri-protect amp-iconfont"></span>
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Uy tín</h2>
                                            <p className="content-text">Luôn lấy "Uy Tín" làm kim chỉ nam trong thời gian hoạt động, chúng tôi sẽ là người bạn đồng hành tốt nhất của bạn.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sub-content">
                                    <div className="d-flex">
                                        <div className="icon-wrapper">
                                            <span class="mbri-growing-chart amp-iconfont"></span>
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Chất lượng</h2>
                                            <p className="content-text">Yếu tố chất lượng sẽ được chúng tôi đặt lên hàng đầu. Các nguyên vật liệu sản xuất sẽ được chúng tôi chọn lọc để cho ra mắt những sản phẩm đi mưa an toàn cho người sử dụng.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="sub-content">
                                    <div className="d-flex">
                                        <div className="icon-wrapper">
                                            <span class="mbri-preview amp-iconfont"></span>
                                        </div>
                                        <div className="text-wrapper">
                                            <h2>Lắng nghe và học hỏi</h2>
                                            <p className="content-text">"Win Win", chúng tôi sẽ luôn lắng nghe và học hỏi những ý kiến đến từ khách hàng để có thể phát triển và cải thiện bản thân một cách tốt nhất.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 align-items-center">
                            <div className="img-wrap raised-1">
                                <img src={photo2} alt="Lion Company" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content-3 content-padding bg-light">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6 order-lg-0 order-1">
                            <div className="video-wrapper raised">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <video controls="true" class="embed-responsive-item">
                                        <source src="https://firebasestorage.googleapis.com/v0/b/lionraincoat-7dc19.appspot.com/o/lionraincoat-video-manufacturing%2FManufacturing_Lion.mp4?alt=media&token=def1d5eb-23a3-4c9d-a9c0-2e28551ddb40" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 mb-3 mb-lg-0">
                            <h1 className="content-title">Một phút tìm hiểu về dây chuyền sản xuất</h1>
                            <p className="content-text">Chúng tôi sở hữu nhiều công đoạn sản xuất khác nhau từ Cắt (Bán Thành Phẩm) May Thành Phẩm, Lăn - Ép Đường May, Kiểm Phẩm, Dập Nút, Xếp - Đóng Gói và Vận Chuyển để cho ra đời những bộ sản phẩm đi mưa chất lượng nhất. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Company
