import React from "react";

import { teams } from "../../data/static-data";

const Teams = () => {
  const TeamItem = ({ name, role, photo }) => {
    return (
      <div className="card">
        <div className="card-wrapper">
          <div className="card-img">
            <img src={photo} alt="team" className="w-100" />
          </div>
          <div className="card-body" style={{height:"132px"}}>
            <h3 className="card-title">{name}</h3>
            <h4 className="card-subtitle">{role}</h4>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="team" className="site-padding bg-light">
      <div className="container">
        <div className="title-section">
          <div className="row justify-content-center text-center">
            <div className="col-12 col-md-10">
              <h2
                className="title mb-3 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay
              >
                đội ngũ
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          {teams.length !== 0 &&
            teams.map((member, index) => (
              <div
                className="col-12 col-md-6 col-lg-3 mb-3"
                data-aos="fade-up"
                data-aos-delay={index * 100 + 100}
                key={index}
              >
                <TeamItem
                  name={member.name}
                  role={member.role}
                  photo={member.photo}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Teams;
