import React from "react";
import { Link } from "react-router-dom";
import mat2 from "../../assets/material/nylon.png";
import mat3 from "../../assets/material/poly.png";
import mat4 from "../../assets/material/pvc.png";

const Material = () => {
  return (
    <div id="materials" className="site-padding bg-light">
      <div className="container">
        <div className="title-section">
          <div className="row justify-content-center text-center">
            <div className="col-md-12 mb-3">
              <h2
                className="title mb-3 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay
              >
                Vật liệu
              </h2>
              <p
                className="lead aos-init aos-animate"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Sau hơn 20 năm sản xuất những bộ áo đi mưa cho các doanh nghiệp
                và người dùng trong và ngoài nước Lionraincoat luôn học hỏi và
                áp dụng nhiều loại vải trong công nghệ sản xuất với nhiều tính
                năng như Chống Thắm Nước, Chống Nhăn, Khử Mùi, Siêu Nhẹ, Siêu
                Thoáng, Giữ Ấm và Chống Rét để phục vụ khách hàng một cách tốt
                nhất. Sau đây là những vật liệu, loại vải mà chúng tôi dùng
                trong công nghệ sản xuất áo đi mưa Lion.
              </p>
              <Link to={"/"}>
                <button
                  className="btn btn-danger text-uppercase"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Xem thêm
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="material-section">
          <div className="row justify-content-center text-center mb-lg-3">
            <div
              className="col-lg-6 mb-4 mb-lg-2"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <div className="showcase-img">
                <img src={mat2} alt="Mat1" className="image" />
                <div className="overlay">
                  <div className="overlay-text-section">
                    <h3 className="mb-3">Nylon</h3>
                    <p className="mb-4 p-ellipsis">
                      Sợi nylon có trọng lượng rất nhẹ, bền nhiệt, có khả năng
                      ngăn chặn gió và nhiệt xâm nhập cơ thể khi đi mưa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div
              className="col-lg-6 mb-4 mb-lg-2"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="showcase-img">
                <img src={mat3} alt="Mat1" className="image" />
                <div className="overlay">
                  <div className="overlay-text-section">
                    <h3 className="mb-3">Polyester</h3>
                    <p className="mb-4 p-ellipsis">
                      Sợi Pyloester giúp chất vải rất sạch, bền bỉ, lâu cũ, màu
                      sắc rất tươi và mới. Đặc biệt là khả năng không hút ẩm sẽ
                      giúp đem đến các ứng dụng chống thắm nước cho những bộ áo
                      đi mưa Lion.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 mb-4 mb-lg-2"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <div className="showcase-img">
                <img src={mat4} alt="Mat1" className="image" />
                <div className="overlay">
                  <div className="overlay-text-section">
                    <h3 className="mb-3">PVC</h3>
                    <p className="mb-4 p-ellipsis">
                      Chất liệu nhựa PVC sẽ tăng khả năng chống ăn mòn của axit,
                      tạo ra sự bền bỉ tuyệt đối cho những bộ áo đi mưa Lion
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Material;
