import React, { useState, useEffect } from "react";
import brand_logo from "../assets/logo-brand-web.png";
import axios from "axios";

import { Link } from "react-router-dom";
import "./Header.css";

function showProductMenu() {
  document.getElementById("product-dropdown-menu").style.display = "block";
}

function hideProductMenu() {
  document.getElementById("product-dropdown-menu").style.display = "none";
}

function showAboutMenu() {
  document.getElementById("about-dropdown-menu").style.display = "block";
}

function hideAboutMenu() {
  document.getElementById("about-dropdown-menu").style.display = "none";
}

function showServiceMenu() {
  document.getElementById("service-dropdown-menu").style.display = "block";
}

function hideServiceMenu() {
  document.getElementById("service-dropdown-menu").style.display = "none";
}

function Header() {
  const [data, setData] = useState([]);

  const fetchCat = async () => {
    const result = await axios("/api/categories");
    setData(result.data);
  };

  useEffect(() => {
    fetchCat();
  }, []);

  return (
    <div>
      <div className="row" id="top-header">
        <div className="col" id="text-top-header">
          <div className="container">
            Chúng tôi cung cấp giải pháp đi mưa hiệu quả cho nhiều doanh nghiệp
            từ 1997
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid pl-4" id="navbar">
          <Link to="/">
            <a className="navbar-brand " href="/#">
              <img
                src={brand_logo}
                width={250}
                height={83}
                className="d-inline-block align-top "
                alt="lionraincoat"
              />
            </a>{" "}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  href="/#"
                  id="dropdown-service"
                  onMouseEnter={showServiceMenu}
                  onMouseLeave={hideServiceMenu}
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  area-aria-expanded="false"
                >
                  DỊCH VỤ<span className="sr-only">(current)</span>
                </a>
                <div
                  class="dropdown-menu"
                  area-aria-labelledby="dropdown-service"
                  id="service-dropdown-menu"
                  onMouseEnter={showServiceMenu}
                  onMouseLeave={hideServiceMenu}
                >
                  <Link to={"/company"} className="dropdown-item">
                    Doanh Nghiệp
                  </Link>
                  <Link to={"/branding"} className="dropdown-item">
                    In Thương Hiệu
                  </Link>
                  <Link to={"/sale"} className="dropdown-item">
                    Khuyến Mãi
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link "
                  id="navbarDropdown"
                  onMouseEnter={showProductMenu}
                  onMouseLeave={hideProductMenu}
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  href="/#"
                >
                  SẢN PHẨM
                </a>
                <div
                  className="dropdown-menu"
                  onMouseEnter={showProductMenu}
                  onMouseLeave={hideProductMenu}
                  aria-labelledby="navbarDropdown"
                  id="product-dropdown-menu"
                >
                  {data.map((catName) => (
                    <Link
                      to={`/products/${catName._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <a className="dropdown-item" href="/#">
                        {catName.name}
                      </a>
                    </Link>
                  ))}
                </div>
              </li>

              <li className="nav-item">
                <Link to="/coporation" id="cor-button-nav">
                  <a className="nav-link" href="/#">
                    HỢP TÁC
                  </a>
                </Link>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link"
                  id="aboutus-button-nav"
                  onMouseEnter={showAboutMenu}
                  onMouseLeave={hideAboutMenu}
                  href="/#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  VỀ CHÚNG TÔI
                </a>
                <div
                  className="dropdown-menu"
                  id="about-dropdown-menu"
                  onMouseEnter={showAboutMenu}
                  onMouseLeave={hideAboutMenu}
                >
                  <Link to="/story" style={{ textDecoration: "none" }}>
                    <a className="dropdown-item" href="/#" id="about-item-1">
                      Câu chuyện áo mưa
                    </a>
                  </Link>
                  <Link to="/companyculture" style={{ textDecoration: "none" }}>
                    <a className="dropdown-item" href="/#" id="about-item-2">
                      Văn hóa công ty
                    </a>
                  </Link>
                  <Link to="/manufacturer" style={{ textDecoration: "none" }}>
                    <a className="dropdown-item" href="/#" id="about-item-3">
                      Nhà máy sản xuất
                    </a>
                  </Link>
                  <Link to="/feedback" style={{ textDecoration: "none" }}>
                    <a className="dropdown-item" href="/#" id="about-item-4">
                      Khách hàng đánh giá
                    </a>
                  </Link>
                  <Link to="/recruitment" style={{ textDecoration: "none" }}>
                    <a className="dropdown-item" href="/#" id="about-item-5">
                      Tuyển dụng
                    </a>
                  </Link>
                 
                </div>
              </li>
            </ul>

            <ul className="navbar-nav contact-section">
              <li className="nav-item mr-2 my-auto">
                <button
                  className="btn btn-danger"
                  id="contact-button-nav"
                  data-toggle="modal"
                  data-target="#asking-price-modal"
                >
                  <i class="fa fa-calculator mr-2" aria-hidden="true"></i>
                  BÁO GIÁ NHANH
                </button>
              </li>

              <li className="nav-item mr-3 my-auto">
                <Link to="/contact">
                  <button className="btn btn-danger" id="contact-button-nav">
                    <i class="fa fa-address-book mr-2" aria-hidden="true"></i>
                    LIÊN HỆ
                  </button>
                </Link>
              </li>

              <li className="nav-item mr-2 my-auto" id="hotline-header">
                <div className="row">
                  <div className="col">HOTLINE</div>
                </div>

                <div className="row" id="phone-header">
                  <div className="col">0339451133 - 0913804925</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
