import React from "react";
import "./Products.css";
import {Link} from 'react-router-dom'

const Products = ({ products }) => {
  const productGrid = products.length !== 0 && products.map((product, index) => (
      <div className="col-sm-6 col-md-4" key={index}>
        <div className="product-container mb-4">
          <div className="container-image" id="container-image">
            <img
              className="w-100"
              alt=""
              src={product.photos && product.photos[0].source}
            />
            <Link to={`/detail/${product._id}`}>
            <div className="overlay">
              <div className="overlay-text">
                <div className="row text-center">
                  <div className="col">
                    <div id="overlay-title">XEM NGAY</div>
                  </div>
                </div>
                <div className="row">
                  <div id="overlay-product-name">{product.name}</div>
                </div>
              </div>
            </div>
            </Link>
          </div>
          <div className="text-wrapper w-100">
            <h5 className="pt-2" id="product-name">
              {product.name}
            </h5>
          </div>
        </div>
      </div>
    ));

  return <div className="row">{productGrid}</div>;
};

export default Products;
