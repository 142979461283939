import React from "react";
import { Carousel } from "antd";
import {Link} from 'react-router-dom'
import SectionBanner from "../section-banner/SectionBanner";
import HeadTag from '../../../utils/HeadTag'

import bannerPhoto from "../../../assets/black.jpg";

import logo from '../../../assets/logo_LION.png'
import photo1 from "../../../assets/hangvn.png";
// import photo2 from "../../../assets/ao-mua-in-quang-cao.jpg";
import photo3 from "../../../assets/checlist.jpeg";

import brand1 from "../../../assets/prints/MẪU ÁO MƯA-01.jpg";
import brand2 from "../../../assets/prints/MẪU ÁO MƯA-02.jpg";
import brand3 from "../../../assets/prints/MẪU ÁO MƯA-03.jpg";
import brand4 from "../../../assets/prints/MẪU ÁO MƯA-04.jpg";
import brand5 from "../../../assets/prints/MẪU ÁO MƯA-05.jpg";

import { production_URL } from "../../../Constant/url";

const brandPhotos = [brand1, brand2, brand3, brand4, brand5];

const Branding = () => {
  return (
    <div id="branding">
      <HeadTag
                title="In thương hiệu | In logo | In slogan lên áo đi mưa - Lionraincoat.com.vn."
                desc="Dịch vụ in thương hiệu, in logo, in slogan của công ty lên các sản phẩm áo đi mưa Lionraincoat.com.vn"
                keywords="áo mưa, in áo mưa, áo mưa khách hàng, áo mưa quảng cáo, áo mưa quà tặng, áo mưa cho doanh nghiệp."
                ogURL={production_URL + '/#/branding'}
                ogImg={logo}
            />
      <SectionBanner
        overlayStyle={{ opacity: "0.8", backgroundColor: "#363636" }}
        titleStyle={{ color: "#B12323" }}
        bgStyle={{ transform: "translateY(-305px)" }}
        bgImage={bannerPhoto}
        title="IN THƯƠNG HIỆU"
        content="Hợp tác ngay với chúng tôi để sở hữu những bộ áo đi mưa cao cấp được in tên thương hiệu của bạn."
      />

      <div className="content-padding content-0 bg-light">
        <div className="container">
          <div className="content-wrapper">
            <h3 className="content-title pb-3">
              Những gì chúng tôi muốn hợp tác
            </h3>
            <p className="content-text">
              Áo mưa được in tên thương hiệu dùng để quảng cáo ngày càng phổ
              biến hiện nay đối với các doanh nghiệp trong và ngoài nước. Trên
              thị trường hiện nay đã có vô vàng bộ áo đi mưa được in logo, tên
              công ty với nhiều mục đích đa dạng như tiếp thị quảng cáo, quà
              tặng khách hàng, quà tặng nhân viên. Tuy nhiên, để cho việc in
              thương hiệu lên áo mưa trở thành một phương tiện hiệu quả trong
              chiến dịch kinh doanh của bạn, chúng ta cần phải sáng suốt chọn ra
              những bộ áo đi mưa độc đáo, thời trang, những nguyên vật liệu sản
              xuất an toàn, cùng với đó là công nghệ in ấn tiên tiến.
            </p>
            <p className="content-text">
              Lionraincoat.com.vn, hay còn được biết đến với thương hiệu Áo Mưa
              Cao Cấp Lion. Với hơn 20 năm nghiêng cứu, lắng nghe và hợp tác với
              các doanh nghiệp trong và ngoài nước, chúng tôi luôn mang trong
              mình sứ mệnh trở thành một người bạn đồng hành trong những mùa mưa
              của người tiêu dùng.
            </p>
          </div>
        </div>
      </div>

      <div className="content-padding content-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <div
                className="content-wrapper"
                style={{ paddingRight: "1.25rem" }}
              >
                <h3 className="content-title pb-3">
                  Những gì chúng tôi đã đạt được
                </h3>
                <p className="content-text">
                  Lionraincoat.com.vn luôn tự hào là một trong những doanh
                  nghiệp được người tiêu dùng trong và ngoài nước tin tưởng
                  trong suốt hơn 20 năm nay. Sản phẩm của chúng tôi đạt được
                  nhiều thành tích cao quý như{" "}
                  <b>"Hàng Việt Nam Chất Lượng Cao" </b> hay{" "}
                  <b>"Thương Hiệu Dẫn Đầu" </b> trong suốt nhiều năm liền.
                </p>
                <p className="content-text">
                  Tuy nhiên, tầm nhìn của chúng tôi không dừng lại tại đó. Chúng
                  tôi còn muốn đem lại một khái niệm hoàn toàn mới về việc đi
                  mưa mà chúng tôi muốn định hình, và đó chính là{" "}
                  <b>"Thời Trang Đi Mưa"</b>.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-wrapper">
                <img
                  alt="branding 1"
                  src={photo1}
                  width={540}
                  height={533}
                  className="w-100 raised"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-us content-padding bg-black">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="c-title">
                <h1 style={{ marginBottom: "0" }}>In thương hiệu</h1>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="btn-section">
                <Link to="/contact"><div
                  onClick={() => console.log("contact")}
                  className="d-flex btn btn-danger"
                >
                  <span class="mbri-info"></span>
                  Liên hệ ngay
                </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-padding content-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <div
                className="content-wrapper"
                style={{ paddingRight: "1.25rem" }}
              >
                <h3 className="content-title pb-3">
                  Ý nghĩa của in ấn thương hiệu trên áo mưa
                </h3>
                <p className="content-text">
                  Những bộ áo đi mưa được Lionraincoat.com.vn sản xuất đã trở
                  lành phương tiện truyền thông cho nhiều doanh nghiệp trong
                  suất những năm qua. Thông qua việc sử dụng các bộ áo đi mưa
                  của Lion cùng với tên thương hiệu, logo, slogan của mình, các
                  doanh nghiệp có thể làm:
                  <ul>
                    <b>
                      <li style={{ color: "#B12323" }}>
                        Quà tặng, vật phẩm quảng cáo
                      </li>
                    </b>
                    <b>
                      <li style={{ color: "#B12323" }}>
                        Trang bị cho cán bộ, công nhân viên
                      </li>
                    </b>
                    <b>
                      <li style={{ color: "#B12323" }}>
                        Quà tặng, vật phẩm từ thiện ...
                      </li>
                    </b>
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-slide-wrapper">
                <Carousel
                    autoplay
                    dots={false}
                >
                  {brandPhotos.length !== 0 &&
                    brandPhotos.map((photo, index) => (
                      <img key={index} src={photo} alt="brands" />
                    ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content-padding content-1 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              <div
                className="content-wrapper"
                style={{ paddingRight: "1.25rem" }}
              >
                <h3 className="content-title pb-3">
                  Tiêu chí in ấn mà Lionraincoat.com.vn áp dụng
                </h3>
                <p className="content-text">
                  Chúng tôi áp dụng những tiêu chí sau vào công nghệ in ấn khi
                  các doanh nghiệp có nhu cầu đặt áo mưa in tên, logo công ty:
                  <ul>
                    <b>
                      <li style={{ color: "#B12323" }}>
                        Kỹ thuật in: Sắc nét, tinh tế từng chi tiết.
                      </li>
                    </b>
                    <b>
                      <li style={{ color: "#B12323" }}>
                        Mực in: bền bỉ theo thời gian, an toàn cho người sử dụng
                      </li>
                    </b>
                    <b>
                      <li style={{ color: "#B12323" }}>
                        Kiểu áo: có thể tùy chọn các dòng sản phẩm khác nhau của
                        Lion để in ấn
                      </li>
                    </b>
                    <b>
                      <li style={{ color: "#B12323" }}>
                        Chất liệu áo: PVC, Nylon, Polyester - An toàn cho người
                        sử dụng
                      </li>
                    </b>
                  </ul>
                </p>

                <p className="content-text">
                  <b>
                    Vị trí in logo, slogan lên áo mưa phổ biến là phía trước
                    ngực trái và sau lưng.
                  </b>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img-wrapper">
                <img alt="branding 1" src={photo3} className="w-100 raised" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branding;
