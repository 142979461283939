import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom'

import { customersData } from '../../data/static-data'

const Customers = () => {
    return (
        <div id="customers" className="site-padding">
            <div className="container">
                <div className="title-section aos-init aos-animate" data-aos="fade-up" data-aos-delay>
                    <h1 className="title " >Khách hàng thân thiết</h1>
                    <Link to={"/"}>
                        <button className="btn btn-danger text-uppercase">Xem thêm</button>
                    </Link>
                </div>

                <div className="customer-slide" data-aos="fade-up" data-aos-delay>
                    <OwlCarousel
                        autoplay
                        nav
                        // navContainer
                        // slideBy={3}
                        loop
                        stagePadding={3}
                        items={5}
                        margin={10}
                        className="owl-theme"
                        navText = {
                            [
                                '<span class="mbri-left"></span>',
                                '<span class="mbri-right"></span>'
                            ]
                        }                       
                        navElement='div'
                    >
                        {customersData.length !== 0 && customersData.map((item, index) => (
                            <div className="item" key={index}>
                                <Link to={item.url} title={item.name}>
                                    <img src={item.photo} alt={`${item.name} logo`} />
                                </Link>
                            </div>
                        ))}

                    </OwlCarousel>

                    {/* <div className="owl-carousel">
                        {customersData.length !== 0 && customersData.map((item, index) => (
                            <div className="item" key={index}>
                                <Link to={item.url} title={item.name}>
                                    <img src={item.photo} alt={`${item.name} logo`} />
                                </Link>
                            </div>
                        ))}
                    </div> */}

                </div>
            </div>

        </div>
    )
}

export default Customers
