import React from 'react'


const ProductSizes = ({ sizes }) => {
    const SizeItem = ({ size, index, id }) => (
        <div className="sc-item pb-1" index={index}>
            <input type="radio" name="sc" id={id} />
            <label for={id}><span className="align-self-center">{size}</span></label>
        </div>
    )

    return (
        <div className="product-sizes">
            <h3>Chọn Size</h3>
            <div className="sizes-list">
                {sizes.length !== 0 && sizes.map((item, index) => (
                    <SizeItem index={index} size={item.name} id={item._id} />
                ))}
            </div>

            <table class="table mt-3">
                <thead>
                    <tr>
                        <th scope="col">SIZE</th>
                        <th scope="col">CHIỀU CAO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">M</th>
                        <td>Dưới 150 cm</td>
                        
                    </tr>
                    <tr>
                        <th scope="row">L</th>
                        <td>Từ 150 đến 159 cm</td>
                       
                    </tr>
                    <tr>
                        <th scope="row">XL</th>
                        <td>Từ 160 đến 169 cm</td>
                        
                    </tr>
                    <tr>
                        <th scope="row">XXL</th>
                        <td>Từ 170 đến 178 cm</td>
                        
                    </tr>
                    <tr>
                        <th scope="row">XXXL</th>
                        <td>Trên 178 cm</td>
                        
                    </tr>

                </tbody>
            </table>
        </div>
    )
}

export default ProductSizes
