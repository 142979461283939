import React from 'react'
import { Helmet } from 'react-helmet'
const HeadTag = ({ title, desc, keywords, ogImg, ogSiteName, ogURL }) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />

            <title>
                {title}
            </title>

            <meta
                name="description"
                property="og:description"
                content={desc}
            />
            <meta property="og:image" content={ogImg}/>
            <meta property="og:image:type" content="image/png"/>
            <meta property="og:site_name" content={ogSiteName? ogSiteName : title}/>
            <meta property="og:url" content={ogURL}/>
            <meta property="og:type" content="Website"/>

            <meta
                name="keywords"
                content={keywords}
            />
            <meta property="og:locale" content="vi_VN"/>
            <meta name="language" content="vietnamese"/>
        </Helmet>
    )
}

export default HeadTag;
