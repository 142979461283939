import photo1 from '../assets/Customers Logos/achison.png'
import photo2 from '../assets/Customers Logos/dongtienviet.jpg'
import photo3 from '../assets/Customers Logos/haiduong.png'
import photo4 from '../assets/Customers Logos/hiepminhphat.png'
import photo5 from '../assets/Customers Logos/hongphat.png'
import photo6 from '../assets/Customers Logos/hungthinh.jpg'
import photo7 from '../assets/Customers Logos/kemnghia.jpg'
import photo8 from '../assets/Customers Logos/khoahoc-congnghevietnam.jpg'
import photo9 from '../assets/Customers Logos/lene.png'
import photo10 from '../assets/Customers Logos/lenguyen.png'
import photo11 from '../assets/Customers Logos/nhattananh.png'
import photo12 from '../assets/Customers Logos/songla.png'






import person1 from '../assets/peoples/customer1.jpg'
import person2 from '../assets/peoples/customer2.jpg'
import person3 from '../assets/peoples/customer3.jpg'
import person4 from '../assets/peoples/customer4.jpg'
import person5 from '../assets/peoples/customer5.jpg'
import person6 from '../assets/peoples/customer6.jpg'
import person7 from '../assets/peoples/customer7.jpg'
import person8 from '../assets/peoples/customer8.jpg'

import product1 from '../assets/product/A12/X/A12-X1.png'
import product2 from '../assets/product/A12/X/A12-X2.png'
import product3 from '../assets/product/A12/X/A12-X3.png'
import product4 from '../assets/product/A12/X/A12-X4.png'
import product5 from '../assets/product/A12/X/A12-X5.png'
import product6 from '../assets/product/A12/X/A12-X6.png'
import product7 from '../assets/product/A12/X/A12-X7.png'
import product8 from '../assets/product/A12/X/A12-X8.png'
import product9 from '../assets/product/A12/X/A12-X9.png'
import product10 from '../assets/product/A12/X/A12-X10.png'
import product11 from '../assets/product/A12/X/A12-X11.png'
import product12 from '../assets/product/A12/X/A12-X12.png'
import product13 from '../assets/product/A12/X/A12-X13.png'
import product14 from '../assets/product/A12/X/A12-X14.png'
import product15 from '../assets/product/A12/X/A12-X15.png'

import team1 from '../assets/peoples/team1.png'
import team2 from '../assets/peoples/team2.png'
import team3 from '../assets/peoples/team3.png'
import team4 from '../assets/peoples/team4.png'


export const customersData =  [
        {
            name: 'Achison',
            photo: photo1,
            url: '/'
        },
        {
            name: 'Đồng Tiến Việt',
            photo: photo2,
            url: '/'
        },
        {
            name: 'Hải Dương',
            photo: photo3,
            url: '/'
        },
        {
            name: 'Hiệp Minh Phát',
            photo: photo4,
            url: '/'
        },
        {
            name: 'Hồng Phát',
            photo: photo5,
            url: '/'
        },
        {
            name: 'Hưng Thịnh',
            photo: photo6,
            url: '/'
        },
        {
            name: 'Kềm Nghĩa',
            photo: photo7,
            url: '/'
        },
        {
            name: 'Khoa Học Công Nghệ Việt Nam',
            photo: photo8,
            url: '/'
        },
        {
            name: 'Lene',
            photo: photo9,
            url: '/'
        },
        {
            name: 'Lê Nguyễn',
            photo: photo10,
            url: '/'
        },
        {
            name: 'Nhật Tân Anh',
            photo: photo11,
            url: '/'
        },
        {
            name: 'Sông La',
            photo: photo12,
            url: '/'
        }
        
        
    ]

export const testimonial_data = [
    {
        name: 'Lê Tuấn',
        role:'Giám Đốc Phát Triển Kinh Doanh',
        company:'LENE',
        content: 'Tôi đã đặt áo mưa cánh dơi để làm quà tặng cho khách hàng. Rất hài lòng về chất lượng và dịch vụ của Lion.',
        icon: person1,
        linkTo: '/'
    },
    {
        name: 'Mr.Pak',
        role:'Manager',
        company:'TRADING 44 NORTH KOREA',
        content: 'Hằng năm, công ty chúng tôi đều đặt số lượng lớn bộ áo đi mưa A3 của Lion cho người dân của chúng tôi. Rất hài lòng về sự hợp tác tốt đẹp giữa hai bên trong nhiều năm.',
        icon: person2,
        linkTo: '/'
    },
    {
        name: 'Bình Trần',
        role:'Quản Lý',
        company:'BÌNH TÂN PHARMACY',
        content: 'Hài lòng khi làm việc với Lionraincoat.com.vn. Tôi hy vọng thời gian tới sẽ có cơ hội để tiếp tục hợp tác với Lionraincoat.com.vn',
        icon: person3,
        linkTo: '/'
    },
    {
        name: 'Thái Ngọc Mai',
        role:'Digital Marketing Director',
        company:'MEDIA LENE',
        content: 'Lionraincoat.com.vn thực sự là một đối tác đáng tin cậy về chất lượng, giá cả cũng như dịch vụ.',
        icon: person4,
        linkTo: '/'
    },
    {
        name: 'Đức Nguyễn',
        role:'Customer',
        company:'Khách Hàng',
        content: 'Vừa nhận được bộ áo đi mưa phản quang 2 lớp. Rất vừa ý và hài lòng về chất lượng và cách đóng gói. Chắc chắn sẽ mua tiếp.',
        icon: person5,
        linkTo: '/'
    },
    {
        name: 'Lan Hoàng',
        role:'Customer',
        company:'Khách Hàng',
        content: 'Chị vừa ý, hoa văn và mẫu mã đẹp. Đi mưa không bị thấm nước',
        icon: person6,
        linkTo: '/'
    },
    {
        name: 'Nguyễn Thái Hằng',
        role:'Customer',
        company:'Khách Hàng',
        content: 'Đồ đẹp lắm, rất hài lòng.',
        icon: person7,
        linkTo: '/'
    },
    {
        name: 'Hồng Lê',
        role:'Customer',
        company:'Khách Hàng',
        content: 'Mình thấy mẫu mã đẹp, chất liệu tốt, giống với hình chụp và đóng gói chuyên nghiệp.',
        icon: person8,
        linkTo: '/'
    },
]

export const sizes = [
    {
        size: 'XXS',
        sizeTag: 'xxs-size'
    },
    {
        size: 'XS',
        sizeTag: 'xs-size'
    },
    {
        size: 'S',
        sizeTag: 's-size'
    },
    {
        size: 'M',
        sizeTag: 'm-size'
    },
    {
        size: 'L',
        sizeTag: 'l-size'
    },
    {
        size: 'XL',
        sizeTag: 'Xl-size'
    },
    {
        size: 'XXL',
        sizeTag: 'xl-size'
    }
]

export const productData = [
    {
        source: product1,
        color: 'Xanh dương',
        colorCode: '#0138BF'
    },
    {
        source: product2,
        color: 'Xanh dương',
        colorCode: '#0138BF'
    },
    {
        source: product3,
        color: 'Xanh dương',
        colorCode: '#0138BF'
    },
    {
        source: product4,
        color: 'Xanh dương',
        colorCode: '#0138BF'
    },
    {
        source: product5,
        color: 'Rêu',
        colorCode: '#139FB3'
    },
    {
        source: product7,
        color: 'Rêu',
        colorCode: '#139FB3'
    },
    {
        source: product6,
        color: 'Rêu',
        colorCode: '#139FB3'
    },
    {
        source: product8,
        color: 'Xanh biển',
        colorCode: '#0052D3'
    },
    {
        source: product9,
        color: 'Xanh biển',
        colorCode: '#0052D3'
    },
    {
        source: product10,
        color: 'Xanh biển',
        colorCode: '#0052D3'
    },
    {
        source: product11,
        color: 'Xanh biển',
        colorCode: '#0052D3'
    },
    {
        source: product12,
        color: 'Đỏ',
        colorCode: '#C1103A'
    },
    {
        source: product13,
        color: 'Đỏ',
        colorCode: '#C1103A'
    },
    {
        source: product14,
        color: 'Đỏ',
        colorCode: '#C1103A'
    },
    {
        source: product15,
        color: 'Đỏ',
        colorCode: '#C1103A'
    }
]

export const teams = [
    {
        name: 'Thanh Huỳnh',
        role: 'Tổng Giám Đốc',
        photo: team1
    },
    {
        name: 'Trâm Võ',
        role: 'Giám Đốc Tài Chính',
        photo: team2
    },
    {
        name: 'Minh Huỳnh',
        role: 'Quản Lý Marketing / Công Nghệ',
        photo: team3
    },
    {
        name: 'Khang Võ',
        role: 'Quản Lý Sản Xuất / Vận Chuyển',
        photo: team4
    }

]