import React from 'react'

const ProductName = ({name}) => {
    return (
        <div className="product-name-wrap">
            <h3 className="product-name">{name}</h3>
        </div>
    )
}

// const style = {
//     discount: {
//         marginBottom: '0px',
//         textDecoration: 'line-through',
//         color: '#2a2a2a',
//         fontWeight: 'normal'
//     }
// }

export default ProductName
