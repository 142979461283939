import React from 'react'
import HomeBanner from './HomeBanner'
import Customers from './Customers';
import FeatureServices from './FeatureServices';
import Material from './Material';
import Techniques from './Technique';
import CustomersFeedback from './CustomersFeedback';
import Achievements from './Achievements';
import Teams from './Teams';
import HeadTag from '../../utils/HeadTag'

import logo from "../../assets/logo_LION.png";
import { production_URL } from '../../Constant/url';

const StaticPage = () => {
    return (
        <div id="home">
             <HeadTag
                title="Lionraincoat.com.vn - Chuyên Gia Áo Đi Mưa Từ Năm 1997"
                desc="Lionraincoat.com.vn là thương hiệu sản xuất thời trang áo quần đi mưa hàng đầu Việt Nam. Cung cấp các giải pháp đi mưa như áo mưa phản quang, áo mưa măng tô, áo mưa cánh dơi, áo mưa đi phượt, áo mưa bộ chính hãng để làm quà tặng, in logo, thương hiệu và slogan cho các doanh nghiệp." 
                keywords="áo mưa, áo mưa bộ, áo mưa cho bé, áo mưa măng tô, áo mưa trẻ em, áo mưa 1 người, áo mưa 2 người, áo mưa cao cấp, áo mưa xe máy, áo mưa phản quang, áo đi mưa, in áo mưa, áo mưa doanh nghiệp, may áo mưa, áo mưa quảng cáo, áo mưa quà tặng"
                ogImg={logo}
                ogURL={production_URL}
            />
            <HomeBanner/>
            <Customers/>    
            <Achievements/>
            <Material/>
            <Techniques/>
            <FeatureServices/>
            <Teams/>
            <CustomersFeedback/>
        </div>
    )
}

export default StaticPage;