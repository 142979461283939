import React from 'react'
import './ScrollToTopButton.css'

function ScrollToTopButton() {
    const scrollToTop = () => {
        var headerID = document.getElementById("header-bar")
        headerID.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }

    return (
        <div>
            <button onClick={scrollToTop} id="myBtn" className="animate__animated animate__backInDown" title="Go to top">
                <i class="mbri-up mbr-iconfont " aria-hidden="true"></i>
            </button>
        </div>
    )
}

export default ScrollToTopButton;
