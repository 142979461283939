import React, { useState } from "react";
import banner from "../../assets/CoporateBanner.jpg";
import fabric from "../../assets/fabric.jpg";
import production from "../../assets/sx.png";
import design from "../../assets/design.png";
import returnShipping from "../../assets/return.png";
import axios from "axios";
import "./Coporation.css";
import "./corporation-responsive.css"
import HeadTag from "../../utils/HeadTag"

function scrollToView() {
  var ele = document.getElementById("coporation-form");
  ele.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
}

export default function Coporation() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const handleSubmit = (evt) => {
    evt.preventDefault();
    const newContact = {
      name: name,
      phone: phone,
    };

    axios.post("/api/contacts/submit", newContact).then((res) => {
      console.log(res);
      console.log(res.data);
    });
    alert("Chúng mừng bạn đã liên hệ thành công!");
    window.location.reload();
  };

  return (
    <div className="coporation">
      <HeadTag
        title="Hợp tác cùng Lionraincoat.com.vn."
        desc= "Hãy hợp tác cùng chúng tôi. Lionraincoat sẽ mang lại cho bạn những giá trị tốt nhất."
        keywords="áo mưa, in áo mưa, áo mưa khách hàng, áo mưa quảng cáo, áo mưa quà tặng, áo mưa cho doanh nghiệp."

      />
      <div className="coporation-banner">
        <img
          src={banner}
          alt="LionraincoatBanner"
          className="image"
          id="image-banner"
        />
        <div className="overlay" style={{ opacity: "0.55" }}></div>
        <div className="center-1">
          <p id="p1">BẮT ĐẦU HỢP TÁC NGAY</p>
          <p id="p2">Với 30+ loại quần áo đi mưa</p>
          <button
            type="button"
            id="nhanchinhsachbtn"
            className="btn btn-danger"
            onClick={scrollToView}
          >
            Nhận Chính Sách Ngay
          </button>
        </div>
      </div>
      <div className="container">
        <div className="coporation-box-1 site-padding">
          <div className="coporation-box-1-info">
            <h5 className="text-center">Về sản phẩm</h5>
            <p className="text-center">
              Với hơn 20 năm kinh nghiệm sản xuất và phân phối sỉ lẻ. Chúng tôi
              thấu hiểu thị hiếu của khách hàng và luôn cố gắng tạo ra nhiều mẫu
              mã với kiểu dáng và công dụng khách nhau.
            </p>
          </div>

          <div className="coporation-box-1-info-2">
            <div className="row">
              <div className="col-lg-6 col-12 mb-3">
                <div className="fabric-container">
                  <img src={fabric} className="image" alt="fabric" />
                  <div className="fabric-container text-center">
                    <h5 className="mt-4">Chất liệu vải</h5>
                    <p className="mt-2">
                      Chúng tôi sản xuất các loại áo mưa với nhiều loại vải từ
                      sơ cấp đến cao cấp như: Nylon, PVC, Vải Vân Cá, Vải Siêu
                      Nhẹ
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12 mb-3">
                <div className="production-container">
                  <img
                    src={production}
                    className="img-fluid"
                    alt="production"
                  />
                  <div className="production-container text-center">
                    <h5 className="mt-4">Công nghệ in</h5>
                    <p className="mt-2">
                      Chúng tôi có thể đáp ứng các nhu cầu in logo lên áo mưa
                      phù hợp với từng doanh nghiệp.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-12 mb-3">
                <div className="design-container">
                  <img src={design} className="image" alt="design" />
                  <div className="design-container text-center">
                    <h5 className="mt-4">Thiết kế</h5>
                    <p className="mt-2">
                      Hơn 30+ mẫu áo đi mưa hiện tại. Chúng tôi không ngừng nảy
                      ra những ý tưởng thiết kế mỗi ngày.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12 mb-3">
                <div className="production-container">
                  <img src={returnShipping} className="image" alt="return" />
                  <div className="return-container text-center">
                    <h5 className="mt-4">Chính sách hỗ trợ đại lý</h5>
                    <p className="mt-2">
                      Lionraincoat.com.vn luôn cam kết hỗ trợ các đại lý để có
                      những chính sách tốt nhất
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="coporation-box-2" id="coporation-form">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-7 col-10">
              <h5>NHẬN CHÍNH SÁCH NGAY</h5>
              <div className="form-coporation">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="exampleInputName" className="label-form">
                      Tên công ty / Tên khách hàng *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputNumber" className="label-form">
                      Điện thoại *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputPassword1"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    id="receive-coporate-btn"
                  >
                    Nhận Chính Sách Ngay!
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
