import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import axios from 'axios'

import { production_URL } from '../../Constant/url'

import ProductColors from './product-colors/ProductColors'
import ProductSizes from './product-sizes/ProductSizes'
import ProductDesc from './product-description/ProductDesc'
import ProductName from './product-name/ProductName'
import ProductCategory from './product-category/ProductCategory'
// import ProductGuide from './product-guides/ProductGuide'
import ProductImage from './product-image/ProductImage'
import HeadTag from './../../utils/HeadTag'

const DetailProduct = (props) => {
    const { id } = props.match.params;
    const [product, setProduct] = useState({})
    const [colors, setColors] = useState([])
    const [color, setColor] = useState('')

    console.log(product)

    const fetchData = async () => {
        const result = await axios.get(`/api/products/${id}`)
        const product = result.data
        let {photos} = product

        const colorList = _.map(photos, (item)=>{
            return {
                color: item.color,
                colorCode: item.colorCode
            }
        })
    
        const colors = _.uniqBy(colorList, 'colorCode')
        
        setProduct(product)
        setColor(colors[0].colorCode)
        setColors(colors)
    }

    useEffect(()=>{
        fetchData()
    },[id])

    const photos = _.filter(product.photos, (photo)=>{
        return photo.colorCode === color
    })

    return (
        <div id="detail-product">
            <HeadTag
                title={`${product.name ? product.name : ''} - Lionraincoat.com.vn`}
                desc="Lionraincoat.com.vn là thương hiệu sản xuất thời trang áo quần đi mưa hàng đầu Việt Nam. Cung cấp các giải pháp đi mưa như áo mưa phản quang, áo mưa măng tô, áo mưa cánh dơi, áo mưa đi phượt, áo mưa bộ chính hãng để làm quà tặng, in logo, thương hiệu và slogan cho các doanh nghiệp." 
                keywords={`${product.name}, ${Object.keys(product).length !== 0 && product.categories.name}, áo mưa, áo mưa bộ, áo mưa cho bé, áo mưa măng tô, áo mưa trẻ em, áo mưa 1 người, áo mưa 2 người, áo mưa cao cấp, áo mưa xe máy, áo mưa phản quang, áo đi mưa, in áo mưa, áo mưa doanh nghiệp, may áo mưa, áo mưa quảng cáo, áo mưa quà tặng`}
                ogImg = {product.photos && product.photos[0].source}
                ogURL ={production_URL + `/#/detail/${id}`}
            />
            <div className="container-fluid mt-3 mb-5" style={{ padding: '0px 25px' }}>
                <div className="row justify-content-center">
                    <div className="col-md-4 order-2 order-md-1">
                        <div className="product-info ">
                            <ProductName name={product.name}/>
                            <ProductCategory category={Object.keys(product).length !== 0 && product.categories.name}/>
                            <ProductDesc desc={product.description} />
                            <ProductSizes sizes={product.sizes? product.sizes : []}/>
                            <ProductColors colors={colors} onChangeColor={(code)=>setColor(code)}/>
                            {/* <ProductGuide/> */}
                        </div>
                    </div>
                    <div className="col-md-8 order-1 order-md-2">
                        <div className="product-media">
                            <ProductImage photos={photos}/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}



export default DetailProduct
