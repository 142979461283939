import React from "react";

const ProductCategory = ({ category }) => {
  return (
    <div className="product-category">
      <ul className="list-group p-0 m-0" style={{ listStyle: "none" }}>
        <li>
          <span style={{ marginRight: "10px" }}>Danh mục:</span>
          <span className="text-bold">{category}</span>
        </li>
        <li>
          <span style={{ marginRight: "10px" }}>Tình trạng:</span>
          <span className="text-bold">Còn hàng</span>
        </li>
      </ul>
    </div>
  );
};

export default ProductCategory;
