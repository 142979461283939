import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import "./sale.css";
import SectionBanner from "../section-banner/SectionBanner";
import HeadTag from "../../../utils/HeadTag";

import bannerPhoto from "../../../assets/backgrounds/assorted-gift-boxes-on-brown-wooden-floor-surface-1050244.jpg";
import logo from "../../../assets/logo_LION.png";

import { production_URL } from "../../../Constant/url";

const Sale = () => {
  const [sales, setSales] = useState([]);

  const fetchData = async () => {
    const result = await axios.get("/api/sales/");
    setSales(result.data);
  };

  const SaleItem = ({ id, title, thumbnail, startedAt, expiredAt, index }) => {
    return (
      <Link to={`/sale/${id}`}>
        <Card
          hoverable
          cover={<img alt={title} className="w-100" src={thumbnail} />}
        >
          <h5 className="card-title">{title}</h5>
          <div className="card-content">
            <time id="startedAt" style={{ fontSize: "12px" }}>
              <span>Bắt đầu: </span>
              <span className="text-warning" style={{ fontWeight: "bold" }}>
                {moment(startedAt).format("DD/MM/YYYY")}
              </span>
            </time>
            <time id="expiredAt" style={{ fontSize: "12px" }}>
              <span>Hết hạn: </span>
              <span className="text-danger" style={{ fontWeight: "bold" }}>
                {moment(expiredAt).format("DD/MM/YYYY")}
              </span>
            </time>
          </div>
        </Card>
      </Link>
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log(sales);
  return (
    <div id="sale">
      <HeadTag
        title="Tin tức khuyến mãi các sản phẩm đi mưa Lionraincoat.com.vn"
        desc="Nơi cập nhật các chương trình khuyến mãi của Lionraincoat.com.vn"
        keywords="khuyến mãi áo mưa, áo mưa giảm giá, áo mưa sale, áo mưa khuyến mãi"
        ogImg={logo}
        ogURL={production_URL + "/#/sale"}
      />
      <SectionBanner
        overlayStyle={{ opacity: "0.65" }}
        bgStyle={{ transform: "translateY(-200px)" }}
        titleStyle={{ color: "#ffffff" }}
        bgImage={bannerPhoto}
        title="Khuyến mãi"
        content="Nơi cập nhật các thông báo khuyến mãi của Lion. "
      />

      <div className="sale-grid site-padding">
        <div className="container">
          <div className="row ">
            {sales.length !== 0 &&
              sales.map((sale, index) => {
                return (
                  <div key={index} className="col-lg-3 col-md-6">
                    <SaleItem
                      id={sale._id}
                      index={index}
                      title={sale.title}
                      thumbnail={sale.thumbnail}
                      startedAt={sale.startedAt}
                      expiredAt={sale.expiredAt}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sale;
