import React from "react";

const HomeBanner = () => {
  return (
    <div id="slide-wrapper">
      <div className="slides">
        <div className="video-overlay"></div>
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/lionraincoat-7dc19.appspot.com/o/lionraincoat-video-manufacturing%2FManufacturing_Lion.mp4?alt=media&token=def1d5eb-23a3-4c9d-a9c0-2e28551ddb40"
            type="video/mp4"
          />
        </video>
        <div className="container h-100">
          <div className="d-flex h-100 text-center align-items-center">
            <div className="w-100 text-white">
              <h1 className="display-3" style={{ color: "#ffffff" }}>
                Quy Trình Sản Xuất
              </h1>
              <p className="lead mb-0" style={{ textTransform: "uppercase" }}>
                Chúng tôi cung cấp giải pháp đi mưa cho nhiều doanh nghiệp trong
                suốt 20 năm qua
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
