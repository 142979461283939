import React from 'react'
import ContactCard from './ContactCard'
import './Contact.css'
export default function Contact() {
    return (
        <div className="container contact">
            <wrapper>
                <h1 className="contact-title text-center">
                    LIÊN HỆ
                </h1>

                {/* Card */}
                <ContactCard/>

            </wrapper>
          
        </div>
    )
}
