import React from 'react'

function About(){
    return(
        <div className="container">
            <h1>This is an about page</h1>
        </div>
    )
}

export default About;