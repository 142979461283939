import React, { useState } from 'react'
import { Modal } from 'antd'

import ImageCarousel from './ImageCarousel'

const ProductImage = ({ photos }) => {
    const [modalVisible, setModalVisible] = useState(false)
    const [crIndex, setCrIndex] = useState(0)

    return (
        <div className="product-images">
            <div className="row">
                {photos.length !== 0 && photos.map((item, index) => (
                    <div className="col-md-6 text-center px-1  mb-3" index={index}>
                        <div className="img-wrap">
                            <img src={item.source} alt="product" className="pd-img img-fluid" />
                            <div className="overlay" onClick={() => {
                                setModalVisible(true)
                                setCrIndex(index)
                            }}>
                                <div className="img-text overlay-text-section">
                                    <i className="mbri-search"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Modal
                footer={null}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                bodyStyle={{
                    backgroundColor: 'transparent',
                    padding: '0'
                }}
                closable={false}
            >
                <ImageCarousel slideIndex={crIndex} photos={photos}/>
            </Modal>
        </div>
    )
}

export default ProductImage
