import React from 'react'
import './Recruitment.css'
import recruitment from '../../assets/recruitment.jpg'
import HeadTag from "../../utils/HeadTag"

export default function Recruitment() {
    return (
        <div className="container recruitment">
            <HeadTag
                title="Tuyển dụng - Lionraincoat.com.vn"
                desc="Hãy trở thành một phần trong đại gia đình của Lionraincoat.com.vn"
                keywords="tuyển dụng, công nhân may, may mặc, may áo mưa, tuyển dụng công nhân"

            />
            <wrapper>
                <div className="recruit-content">
                    <div className="row">
                        <div className="col-12 col-lg-6 order-1 order-lg-0">
                            <h5 id="recruit-title">Lion Raincoat - Tuyển Dụng</h5>
                            <p className="pt-3">
                                Tại Lionraincoat.com.vn, chúng tôi đã & đang là một đại gia đình, nơi mọi người sống, làm việc, chia sẻ & cùng nhau trải nghiệm.
                                Mọi người sẽ được làm công việc họ muốn, được dấn thân với những gì họ đam mê, được tôn trọng những gì thuộc về bản thân.
                                Nếu những điều trên làm bạn hứng thú, hãy ứng tuyển vào một vị trí tại Lionraincoat.com.vn và bạn sẽ là một thành viên tiếp theo của gia đình chúng tôi.
                        </p>
                            <p>Hãy chọn cho bạn một môi trường làm việc hoàn toàn mới với nhiều trải nghiệm thú vị.</p>
                            <p>Thông tin gửi về mail: info@lionraincoat.com.vn</p>

                        </div>
                        <div className="col-12 col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0">
                            <div className="container">
                                <img src={recruitment} alt="recruitment" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </wrapper>
        </div>
    )
}
