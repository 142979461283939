import React from "react";
import { Link } from "react-router-dom";

const services = [
  {
    title: "Doanh Nghiệp",
    content:
      "Với hơn 20 năm là đối tác của các doanh nghiệp trong và ngoài nước, chúng tôi tự tin sẽ luôn là người bạn đồng hành và cung cấp giải pháp đi mưa cho các doanh nghiệp.",
    url: "/company",
    iconClass: "mbri-delivery"
  },
  {
    title: "In thương hiệu",
    content:
      "Chúng tôi áp dụng những công nghệ in ấn tiên tiến để in logo, tên thương hiệu lên các sản phẩm áo đi mưa Lion.",
    url: "/branding",
    iconClass: "mbri-change-style"
  },
  {
    title: "Khuyến mãi",
    content:
      "Chúng tôi luôn có những chương trình khuyến mãi để đem đến những cơ hội sở hữu các sản phẩm đi mưa Lion của chúng tôi,",
    url: "/sale",
    iconClass: "mbri-sale"
  },
];

const FeatureServices = () => {
  const ServiceItem = ({ title, content, url, iconClass, index }) => {
    return (
      <div
        className="col-md-6 col-lg-4 mb-4"
        data-aos="fade-up-right"
        data-aos-delay={index * 100 + 100}
        data-aos-duration="450"
      >
        <div className="service-1">
          <div className="service-box">
          <div className="wrap-icon">
            <span className={`iconfont ${iconClass}`}></span>
          </div>
          <div className="service-content-wrap">
            <h3>{title}</h3>
            <p className="pb-4 align-center">{content}</p>
            
          </div>
          <div className="link-wrap">
              <Link to={url}>
              <h4 className="link">Tìm hiểu thêm</h4>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="feature-services" className="site-padding">
      <div className="container">
        <div className="title-section">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-10 mb-3">
              <h2
                className="title mb-3"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Dịch vụ tiêu biểu
              </h2>
              <p className="lead" data-aos="fade-up" data-aos-delay="100">
              Với sứ mệnh mang lại những giải pháp phù hợp nhất cho từng khách hàng trong và ngoài nước. Chúng tôi
                            luôn chủ động lắng nghe, nghiêng cứu và tạo ra những dịch vụ mới để đáp ứng với nhu cầu đa dạng của khách hàng.
              </p>
              <Link to={"/"}>
                <button
                  className="btn btn-danger text-uppercase"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Xem thêm
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className="service-section">
          <div className="row justify-content-center text-center">
            {services.length !== 0 &&
              services.map((service, index) => (
                <ServiceItem
                  title={service.title}
                  content={service.content}
                  url={service.url}
                  index={index}
                  iconClass={service.iconClass}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureServices;
