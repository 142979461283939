import React, { useRef, useEffect } from 'react'
import { Carousel } from 'antd'

const ImageCarousel = ({ photos, slideIndex }) => {
    const crsousel = useRef()

    useEffect(()=>{
        crsousel.current.goTo(slideIndex, false)
    },[slideIndex])
    
    return (
        <>
            <Carousel ref={crsousel} dotPosition={'right'} effect='fade'>
                {photos.length !== 0 && photos.map((item, index) => (
                    <img src={item.source} alt="product" className="img-fluid" index={index} />
                ))}
            </Carousel>
        </>
    )
}

export default ImageCarousel
