import React from "react";
import "./Feedback.css";
import HeadTag from "../../utils/HeadTag"


import { testimonial_data } from "../../data/static-data";

// name: 'Lê Tuấn',
//         role:'Giám Đốc Phát Triển Kinh Doanh',
//         company:'LENE',
//         content: 'Tôi đã đặt áo mưa cánh dơi để làm quà tặng cho khách hàng. Rất hài lòng về chất lượng và dịch vụ của Lion.',
//         icon: person1,
//         linkTo: '/'

function Feedback() {
  const CustomerItem = ({ name, role, company, content, icon, linkTo, index }) => {
    return (
      <div className="card customer-item raised-1">
        <HeadTag
          title="Đánh giá từ những người bạn đồng hành của Lionraincoat.com.vn."
          desc="Chúng tôi luôn lắng nghe và tôn trọng mọi đánh giá, ý kiến của khách hàng."
          keywords="áo mưa, in áo mưa, áo mưa khách hàng, áo mưa quảng cáo, áo mưa quà tặng, áo mưa cho doanh nghiệp."

        />
        <div className="card-img">
          <img className="card-img-top" src={icon} alt={`Customer ${index + 1}`} />
        </div>
        <div className="card-body">
          <h5 className="card-title" id="customer-name">
            {name}
          </h5>
          <h6 className="card-title" id="customer-workplace">
            {role}
            {company &&
              <>
                <br />
                <strong className="text-uppercase">{company}</strong>
              </>
            }
          </h6>
          <p className="card-text" id="customer-feedback-content">
            {content}
          </p>

        </div>
        <div className="feedback-wrap">
          <hr />
          <div className="p-3">
            <div className="row text-center">
              <div className="col-6" id="chitieu">
                Độ hài lòng
              </div>
              <div className="col-6" id="chitieu">
                Đặt hàng lần nữa
              </div>
            </div>

            <div className="row text-center">
              <div className="col-6" id="chitieu2">
                <i class="fa fa-heart pr-1" aria-hidden="true"></i>
                GOOD
              </div>
              <div className="col-6" id="chitieu2">
                <i class="fa fa-thumbs-up mt-1 pr-1" aria-hidden="true"></i>
                YES
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container feedback-about">
      <wrapper>
        <div className="row">
          <div className="col">
            <div className="text-center">
              <h5 id="feedback-title">Đánh giá của khách hàng</h5>
              <h7 id="feedback-subtitle">
                Đây là những đánh giá của các khách hàng gần đây
              </h7>
            </div>
          </div>
        </div>
        <div className="customer-feed-section mt-5">
          <div className="row">
            {testimonial_data.length !== 0 &&
              testimonial_data.map((customer, index) => (
                <div className="col-12 col-md-6 col-lg-4 mb-3" key={index}>
                  <CustomerItem
                    icon={customer.icon}
                    name={customer.name}
                    role={customer.role}
                    company={customer.company}
                    content={customer.content}
                    linkTo={customer.linkTo}
                    index={index}
                  />
                </div>
              ))}
          </div>
        </div>
      </wrapper>
    </div>
  );
}

export default Feedback;
