import React from 'react';
import { HashRouter, BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AOS from "aos";

import "aos/dist/aos.css";
import './App.css';
import './antd.css'
import './assets/mobirise-icons/mobirise-icons.css'

import About from './components/About/About'
import StaticPage from './components/StaticPage/StaticPage'
import Contact from './components/Contact/Contact'
import Header from './layouts/Header'
import Footer from './layouts/Footer'
import InfoBanner from './components/InfoBanner/InfoBanner'
import Coporation from './components/Coporation/Coporation'
import Story from './components/About/Story'
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton'
import CompanyCulture from './components/About/CompanyCulture'
import Manufacturer from './components/About/Manufacturer'
import Feedback from './components/About/Feedback'
import Recruitment from './components/About/Recruitment'
import AskingPriceBox from './components/AskingPriceBox/AskingPriceBox'
import DetailProduct from './components/DetailProduct/DetailProduct';
import DetailService from './components/DetailService/DetailService'
import ProductListPage from './components/ProductListPage/ProductListPage'
import Company from './components/Services/Company/Company';
import Branding from './components/Services/Branding/Branding';
import Sale from './components/Services/Sale/Sale';
import SaleDetails from './components/Services/Sale/SaleDetails';
import ScrollTopTop from './components/ScrollTopTop'

AOS.init({
  duration: 1200,
});

function App() {
  return (
    <div className='root'>
      <Router>
        <div id="header-bar"><Header/></div>
        <AskingPriceBox/>
        <div className="body">
          <ScrollTopTop/>
          <Switch>
            <Route exact path='/' component={StaticPage}></Route>
            <Route exact path='/about' component={About}></Route>
            <Route exact path='/contact' component={Contact}></Route>
            <Route exact path='/coporation' component={Coporation}></Route>
            <Route exact path='/story' component={Story}></Route>
            <Route exact path='/companyculture' component={CompanyCulture}></Route>
            <Route exact path='/manufacturer' component={Manufacturer}></Route>
            <Route exact path='/feedback' component={Feedback}></Route>
            <Route exact path='/recruitment' component={Recruitment}></Route>
            <Route exact path='/detail/:id' component={DetailProduct}></Route>
            <Route exact path='/service' component={DetailService}></Route>
            <Route exact path='/products/:id' component={ProductListPage}/>
            <Route exact path='/company' component={Company}></Route>
            <Route exact path='/branding' component={Branding}></Route>
            <Route exact path='/sale' component={Sale}></Route>
            <Route exact path='/sale/:id' component={SaleDetails}></Route>
          </Switch>
          <div><ScrollToTopButton/></div>
        </div>
        <InfoBanner/>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
