import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import bocongthuong from "../assets/bocongthuong.png";
import hangvn from "../assets/hangvn.png";
function Footer() {
  return (
    <div id="footer-section">
      <footer>
        <div className="container" id="footer-top">
          <div className="row">
            <div className="col-lg-4 col-12 wrap">
              <div className="footerbox-1 mb-3">
                <h3 id="first-column-title">
                  MỘT PHÚT TÌM HIỂU VỀ ÁO MƯA LION
                </h3>
                <div class="embed-responsive embed-responsive-16by9">
                  <video controls="true" class="embed-responsive-item">
                    <source src="https://firebasestorage.googleapis.com/v0/b/lionraincoat-7dc19.appspot.com/o/lionraincoat-video-manufacturing%2FManufacturing_Lion.mp4?alt=media&token=def1d5eb-23a3-4c9d-a9c0-2e28551ddb40" type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="footerbox-2">
                <h3 id="first-column-title">THEO DÕI CHÚNG TÔI</h3>
                <i href="/#" class="fa fa-facebook" id="fa-footer"></i>
                <i href="/#" class="fa fa-instagram" id="fa-footer"></i>
              </div>
            </div>

            {/* second column footer */}
            <div className="col-lg-3 col-12 wrap">
              <div className="footerbox-3 mb-3">
                <h3 id="first-column-title">VỀ CHÚNG TÔI</h3>
                <ul id="list-second-column-footer">
                  <li>
                    <Link to="/story" style={{ textDecoration: "none" }}>
                      <a href="/#" id="info-footer">
                        Câu Chuyện Áo Mưa
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/companyculture"  style={{ textDecoration: "none" }}>
                      <a href="/#" id="info-footer">
                        Văn Hóa Công Ty
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/manufacturer"  style={{ textDecoration: "none" }}>
                      <a href="/#" id="info-footer">
                        Nhà Máy Sản Xuất
                      </a>
                    </Link>
                  </li>

                  <li>
                    <Link to="/recruitment"  style={{ textDecoration: "none" }}>
                      <a href="/#" id="info-footer">
                        Tuyển Dụng
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/feedback"  style={{ textDecoration: "none" }}>
                      <a href="/#" id="info-footer">
                        Đánh Giá Khách Hàng
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="footerbox-4">
                <h3 id="first-column-title">THÔNG TIN THAM KHẢO</h3>
                <ul id="list-second-column-footer">
                  <li>
                    <Link style={{ textDecoration: "none" }}>
                      <a id="info-footer" href="/#">
                        Câu Hỏi Thường Gặp
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link style={{ textDecoration: "none" }}>
                      <a id="info-footer" href="/#">
                        Bảng Giá
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link style={{ textDecoration: "none" }}>
                      <a id="info-footer" href="/#">
                        Tin Tức
                      </a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* third column footer */}
            <div className="col-lg-5 col-12 wrap">
              <div className="footerbox-5">
                <button
                  className="btn btn-danger"
                  id="contact-button-foot"
                  data-toggle="modal"
                  data-target="#asking-price-modal"
                >
                  <i class="fa fa-calculator mr-2" aria-hidden="true"></i>
                  BÁO GIÁ NHANH
                </button>

                <Link to="/contact">
                  <button className="btn btn-danger" id="contact-button-foot">
                    <i class="fa fa-address-book mr-2" aria-hidden="true"></i>
                    LIÊN HỆ
                  </button>
                </Link>
              </div>

              <div className="footerbox-6">
                <ul id="list-contact-column-footer">
                  <li>
                    <Link style={{ textDecoration: "none" }}>
                      <a id="info-footer" href="/#">
                        <i
                          className="fa fa-phone"
                          aria-hidden="true"
                          id="fa-footer-2"
                        ></i>
                        0339451133 - 0913804925
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link style={{ textDecoration: "none" }}>
                      <a id="info-footer" href="/#">
                        <i
                          className="fa fa-comments-o"
                          aria-hidden="true"
                          id="fa-footer-2"
                        ></i>
                        Chat Với Chúng Tôi
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link style={{ textDecoration: "none" }}>
                      <a id="info-footer" href="/#">
                        <i
                          className="fa fa-envelope"
                          aria-hidden="true"
                          id="fa-footer-2"
                        ></i>
                        info@lionraincoat.com.vn
                      </a>
                    </Link>
                  </li>
                </ul>
                <div className="footer-logos">
                  <img
                    src={bocongthuong}
                    width="50%"
                    height="50%"
                    className="d-inline-block align-top "
                    alt="lionraincoat"
                    style={{paddingRight: "5px"}}
                  />
                  <img
                    src={hangvn}
                    width="40%"
                    height="40%"
                    className="d-inline-block align-top "
                    alt="lionraincoat"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="row" id="bottom-footer">
        <div className="col" id="text-bottom-footer">
          <div className="container">
            <p>CÔNG TY TNHH SX-TM THANH BÌNH NGUYÊN</p>
            <p>NHÃN HIỆU ÁO ĐI MƯA LION</p>
            <p>
              Địa chỉ: H30 - H31 Cư Xá Phú Lâm B, Phường 13, Quận 6, TP.Hồ Chí
              Minh
            </p>
            <p>MST: 0310562739</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
